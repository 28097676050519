import { Component, Input, OnInit } from '@angular/core';
import { v4 as uuid } from 'uuid';

import { EMPTY, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { LOGO_UPLOADER_TITLES, SnackBarHelperComponent, ValidatorHelper } from '../../../../../helpers';
import { Company, Contact, ContactClient, GetAddContactValidation, State } from '../../../../../interfaces';
import { UserModel } from '../../../../../models';
import { CompanyService } from '../../../../../services/company.service';
import { S3Service } from '../../../../../services/s3.service';
import { StateService } from '../../../../../services/state.service';
import { UserService } from '../../../../../services/user.service';
import { AddContactTextConst } from './common/add-contact-person.constants';

@Component({
	selector: 'app-add-contact-person-dialog',
	templateUrl: '../../add-contact-person-dialog.component.html',
	styleUrls: ['../../add-contact-person-dialog.component.css'],
})
export class AddContactPersonDialogComponent implements OnInit {
	@Input()
	public client: ContactClient;

	public addContactPromise: Promise<ContactClient>;
	public contactValidators;
	public estimators: Observable<UserModel[]>;
	public imageFile: File | Blob;
	public newContact: Contact = {
		name: '',
		estimators: [],
	};
	public newEstimator: UserModel;
	public pictureTitle = LOGO_UPLOADER_TITLES.picture;
	public states: Observable<State[]>;
	public sameAsClient: boolean = false;
	public text: typeof AddContactTextConst = AddContactTextConst;

	constructor(
		private userService: UserService,
		private companyService: CompanyService,
		private snack: SnackBarHelperComponent,
		private s3Service: S3Service,
		private stateService: StateService
	) {}

	public ngOnInit() {
		this.estimators = this.userService.getUsers({});
		this.states = this.stateService.postList({ isActive: true });
		this.contactValidators = GetAddContactValidation();
	}

	public addContactPerson(): Observable<ContactClient> {
		const errors = ValidatorHelper.checkErrors(this.contactValidators);
		if (errors) {
			this.snack.snackError(errors);
			return EMPTY;
		}

		if (this.imageFile) {
			this.newContact.displayPictureUrl = null;

			return this.uploadImage(this.imageFile).pipe(
				tap((pictureKey: string) => {
					this.newContact.displayPictureKey = pictureKey;
				}),
				switchMap(() => {
					return this.companyService.addContact(this.client.company.id, this.newContact);
				}),
				map((company: Company) => {
					this.client.company = company;
					this.client.contact = this.newContact;
					return this.client;
				})
			);
		} else {
			return this.companyService.addContact(this.client.company.id, this.newContact).pipe(
				map((company: Company) => {
					this.client.company = company;
					this.client.contact = this.newContact;
					return this.client;
				})
			);
		}
	}

	public addEstimatorToNewContact(estimator): void {
		// guard
		if (!estimator) {
			return;
		}

		if (!this.newContact.estimators) {
			this.newContact.estimators = [];
		}

		if (this.newEstimator && this.newContact.estimators.indexOf(this.newEstimator) < 0) {
			this.newContact.estimators.push(this.newEstimator);
			this.newEstimator = undefined;
		}
	}

	public customSearch(term: string, item: any): boolean {
		term = term.toLocaleLowerCase();
		return (
			item.firstName.toLocaleLowerCase().indexOf(term) > -1 ||
			item.lastName.toLocaleLowerCase().indexOf(term) > -1 ||
			(item.firstName + ' - ' + item.lastName).toLocaleLowerCase().indexOf(term) > -1
		);
	}

	public setFile(file: File): void {
		this.imageFile = file;
	}

	/**
	 * Uploads a image file and get the aws key.
	 * @param file
	 */
	private uploadImage(file: File | Blob): Observable<string> {
		const svgBlob = new Blob([file], { type: 'image/png+svg' });
		const path = `client/${this.client.company.id}/contact/picture_${new Date().toISOString()}_${uuid()}`;
		return this.s3Service.uploadFile(svgBlob, path, svgBlob.type);
	}

	public onSameAsClient() {
		if (this.sameAsClient) {
			this.newContact = {
				name: this.client.company.name,
				email: this.client.company.email,
				phone: this.client.company.phone,
				addressLine1: this.client.company.addressLine1 || '',
				addressLine2: this.client.company.addressLine2 || '',
				addressLine3: this.client.company.addressLine3 || '',
				city: this.client.company.city || '',
				state: this.client.company.state || undefined,
				postalCode: this.client.company.postalCode,
				estimators: this.newContact.estimators,
			}
		} else {
			this.newContact = {
				name: '',
				email: '',
				phone: '',
				addressLine1: '',
				addressLine2: '',
				addressLine3: '',
				city: '',
				state: undefined,
				postalCode: '',
				estimators: this.newContact.estimators,
			}
		}
	}
}
