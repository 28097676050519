import { AddressDetail } from './address-detail';

export interface EntityEditRequest {
	address: AddressDetail;
	email: string;
	id: string;
	language: string;
	name: string;
	countryCode: string;
	phone: string;
	states: string[]; // Array of state ids
	timezone: string;
	fiscalMonthStart: number;
}
