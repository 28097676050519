import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { DropdownData } from '../dialog/components/charges/common/charges.interfaces';
import { Constants } from '../helpers';
import {
	Competitor,
	DistinctCounts,
	EmbeddedCoatingSystem,
	EmbeddedSupplier,
	ExportDocument,
	ExportDocumentsResponse,
	ItemsResponse,
	ItemsResponseData,
	LibrarySort,
	Product,
	Project,
	ProjectHoursAndRevenue,
	ProjectResponse,
	ProjectSaveObj,
	ProjectSearchSummary,
	ProjectsFullListSearchResponse,
	ProjectsResponse,
	ProjectsSearchResponse,
	ReferenceCode,
} from '../interfaces';
import { ColourModel, DistributionSummaryModel } from '../models';
import { Timezone } from '../interfaces/timezones';
import { LiveProjectResult, LiveProjectTotals } from '../models';

@Injectable({
	providedIn: 'root',
})
export class ProjectService {
	constructor(private http: HttpClient) {}

	/**
	 * Adds a new project
	 * @param {Project} project
	 * @returns {Observable<Project>}
	 */
	public addProject(project: Partial<ProjectSaveObj>): Observable<Project> {
		return this.http.post<ProjectResponse>(`${Constants.BASE_API_URL}/project/`, project).pipe(
			map((res: ProjectResponse) => {
				return new Project(res.project);
			})
		);
	}

	/**
	 * Clones a project
	 * @param projectId
	 * @param updatedFields
	 * @returns {Observable<Project>}
	 */
	public cloneProject(projectId, updatedFields): Observable<Project> {
		return this.http
			.post<ProjectResponse>(`${Constants.BASE_API_URL}/project/clone`, {
				projectId,
				updatedFields,
			})
			.pipe(
				map((res: ProjectResponse) => {
					return new Project(res.project);
				})
			);
	}

	/**
	 * Edit project
	 * @param {ProjectSaveObj} project
	 * Copies a project matching projectId
	 * @returns {Observable<Project>}
	 */
	public editProject(project: Partial<ProjectSaveObj>): Observable<Project> {
		// Do not override chargeOrder as we are saving it elsewhere
		const postObject = Object.assign({}, project);
		delete postObject.chargeOrder;

		return this.http
			.post<ProjectResponse>(`${Constants.BASE_API_URL}/project/edit`, {
				projectId: project.id,
				project: postObject,
			})
			.pipe(
				map((res: ProjectResponse) => {
					return new Project(res.project);
				})
			);
	}

	/**
	 * Updates a projects discount value (project.discount or project.discountPercentage depending on project.isDiscountPercentage)
	 * @param {string} projectId
	 * @param {number} discountValue
	 * @param isDiscountPercentage
	 * @returns {Observable<Project>}
	 */
	public editProjectDiscountValue(projectId: string, discountValue: number, isDiscountPercentage: boolean): Observable<Project> {
		return this.http.post<ProjectResponse>(`${Constants.BASE_API_URL}/project/edit-discount`, { projectId, discountValue, isDiscountPercentage }).pipe(
			map((res: ProjectResponse) => {
				return new Project(res.project);
			})
		);
	}

	/**
	 * Updates a projects discountType
	 * @param {string} projectId
	 * @param {string} discountType
	 * @returns {Observable<Project>}
	 */
	public editProjectDiscountType(projectId: string, discountType: string): Observable<Project> {
		return this.http.post<ProjectResponse>(`${Constants.BASE_API_URL}/project/edit-discount-type`, { projectId, discountType }).pipe(
			map((res: ProjectResponse) => {
				return new Project(res.project);
			})
		);
	}

	/**
	 * Adds new workers to a project as "shiftWorkers"
	 * @param projectId 
	 * @param newShiftWorkerIds 
	 * @returns 
	 */
	public addShiftWorkers(projectId: string, newShiftWorkerIds: string[]): Observable<Project> {
		return this.http.post<ProjectResponse>(`${Constants.BASE_API_URL}/project/add-shift-workers`, { projectId, newShiftWorkerIds }).pipe(
			map((res: ProjectResponse) => {
				return new Project(res.project);
			})
		);
	}

	/**
	 * Updates a projects status
	 * @param {string} projectId
	 * @param {string} status
	 * @param {Date} statusDate
	 * @param {string} statusReason
	 * @param {string} statusOtherReason
	 * @param {Competitor} lostCompetitor
	 * @param {string} clientWonLost
	 * @param {Date} quoteDueDate
	 * @param {Date} submittedDate
	 * @param {Date} startDate
	 * @param {Date} finishDate
	 * @param {string} contractAdministrator
	 * @param {string} projectManager
	 * @returns {Observable<Project>}
	 */
	public editProjectStatus(
		projectId: string,
		status: string,
		statusDate: Date,
		statusReason?: string,
		statusOtherReason?: string,
		lostCompetitor?: Competitor,
		clientWonLost?: string,
		quoteDueDate?: Date,
		submittedDate?: Date,
		// These are required on project status = won, make required on client side
		startDate?: Date,
		finishDate?: Date,
		contractAdministrator?: string,
		projectManager?: string,
	): Observable<Project> {
		const lostCompetitorId: string = lostCompetitor ? lostCompetitor.id : null;

		return this.http
			.post<ProjectResponse>(`${Constants.BASE_API_URL}/project/edit-status`, {
				projectId,
				status,
				statusDate,
				statusReason,
				statusOtherReason,
				lostCompetitorId,
				clientWonLost,
				quoteDueDate,
				submittedDate,
				startDate,
				finishDate,
				contractAdministrator,
				projectManager,
			})
			.pipe(
				map((res: ProjectResponse) => {
					return new Project(res.project);
				})
			);
	}

	/**
	 * Change the operating hours, and shift duration for a project
	 * @param {string} projectId 
	 * @param {object} operatingHours
	 * @param {number} shiftDuration
	 * @param {number} mealBreakLength
	 * @param {Timezone} timezone
	 * @param {Date} projectStartDate
	 * @param {Date} projectFinishDate
	 * @returns 
	 */
	public editProjectShiftSettings(
		projectId: string,
		operatingHours: any,
		shiftDuration: number,
		mealBreakLength: number,
		timezone: Timezone,
		projectStartDate: Date,
		projectFinishDate: Date,
	): Observable<Project> {
		return this.http
			.post<ProjectResponse>(`${Constants.BASE_API_URL}/project/edit-shift-settings`, {
				projectId,
				operatingHours,
				shiftDuration,
				mealBreakLength,
				timezone,
				projectStartDate,
				projectFinishDate,
			})
			.pipe(
				map((res: ProjectResponse) => {
					return new Project(res.project);
				})
			);
	}

	/**
	 * Get a project for the given id
	 * @param {string} projectId
	 * @returns {Observable<Project>}
	 */
	public getProject(projectId: string): Observable<Project> {
		return this.http
			.post<ProjectResponse>(`${Constants.BASE_API_URL}/project/details`, {
				projectId,
			})
			.pipe(
				map((res: ProjectResponse) => {
					return new Project(res.project);
				})
			);
	}

	/**
	 * Returns project total hours and revenue for a company within a date range.
	 * @param search
	 * @returns {Observable<{ totalQuotedHours: number, totalRevenue: number, totalWonHours: number}>}
	 */
	public getProjectHoursAndRevenue(search): Observable<ProjectHoursAndRevenue> {
		return this.http
			.post<{
				result: {
					totalQuotedHours: number;
					totalRevenue: number;
					totalWonHours: number;
				};
			}>(`${Constants.BASE_API_URL}/project/project-hours-and-revenue`, search)
			.pipe(
				map((res: { result: ProjectHoursAndRevenue }) => {
					return res.result;
				})
			);
	}

	/**
	 * Returns a distinct list of project names that the user is the estimator of
	 * @param userId
	 * @param status
	 * @returns {Observable<string[]>}
	 */
	public getProjectNames(userId: string, status?: string, isActive?: boolean): Observable<string[]> {
		return this.http
			.post<ItemsResponse>(`${Constants.BASE_API_URL}/project/project-names`, {
				userId,
				status,
				isActive,
			})
			.pipe(
				map((res: ItemsResponse) => {
					return res.items;
				})
			);
	}

	/**
	 * Returns a list of project filters to be used as a dropdowns
	 * @param projectId
	 * @param isBoq Flag to return only query boq type charges
	 * @param variationId Return filters for this variationId
	 * @returns
	 */
	public getProjectFilterDropdowns(projectId: string, isBoq: boolean = false, variationId?: string): Observable<DropdownData> {
		return this.http.post<ItemsResponse>(`${Constants.BASE_API_URL}/project/project-filter-dropdowns`, { projectId, isBoq, variationId }).pipe(
			map((res: ItemsResponse) => {
				return res.items;
			})
		);
	}

	/**
	 * Returns a distinct list of projects the user is the estimator of
	 * @param userId
	 * @returns {Observable<Project[]>}
	 */
	public getUserProjects(userId: string): Observable<Project[]> {
		return this.http
			.post<ItemsResponse>(`${Constants.BASE_API_URL}/project/user-projects`, {
				userId,
			})
			.pipe(
				map((res: ItemsResponse) => {
					return res.items;
				})
			);
	}

	/**
	 * Gets top 10 Recent projects
	 * @returns {Observable<Project[]>}
	 */
	public getRecentProjects(userIds: string[], statuses: string[]): Observable<Project[]> {
		return this.http
			.post<ProjectsResponse>(`${Constants.BASE_API_URL}/project/recent/`, {
				userIds,
				statuses,
			})
			.pipe(
				map((res: ProjectsResponse) => {
					return res.projects;
				})
			);
	}

	/**
	 * Tells if a user has projects.
	 * @param userId
	 * @returns {Observable<boolean>}
	 */
	public isEstimatorOnProjects(userId: string): Observable<boolean> {
		return this.http.post<{ isEstimatorOnProjects: boolean }>(`${Constants.BASE_API_URL}/project/is-estimator-on-projects`, { userId }).pipe(
			map((res: { isEstimatorOnProjects: boolean }) => {
				return res.isEstimatorOnProjects;
			})
		);
	}

	/**
	 * Returns all distinct coatingSystems in a projects boqs
	 * @param projectId
	 * @returns {Observable<CoatingSystem[]>}
	 */
	public postCoatingSystems(projectId: string): Observable<EmbeddedCoatingSystem[]> {
		return this.http.post<{ coatingSystems: EmbeddedCoatingSystem[] }>(`${Constants.BASE_API_URL}/project/list-coatingsystem`, { projectId }).pipe(
			map((res: { coatingSystems: EmbeddedCoatingSystem[] }) => {
				return res.coatingSystems;
			})
		);
	}

	/**
	 * Gets totalCount
	 * @returns {Observable<number>}
	 */
	public postCount(search): Observable<number> {
		return this.http.post<any>(`${Constants.BASE_API_URL}/project/count`, { params: search }).pipe(map(res => res.total));
	}

	/**
	 * Returns all distinct colours in a projects boqs
	 * @param projectId
	 * @returns {Observable<Colour[]>}
	 */
	public postDistinctColours(projectId: string): Observable<ColourModel[]> {
		return this.http.post<{ colours: ColourModel[] }>(`${Constants.BASE_API_URL}/project/list-colour`, { projectId }).pipe(
			map((res: { colours: ColourModel[] }) => {
				return res.colours;
			})
		);
	}

	/**
	 * Returns all distinct colours, coatingSystems, products and suppliers in a projects boqs
	 * @param projectId
	 * @returns {Observable<DistinctCounts>}
	 */
	public postDistinctCounts(projectId: string): Observable<DistinctCounts> {
		return this.http.post<{ distinctCounts: DistinctCounts }>(`${Constants.BASE_API_URL}/project/list-count`, { projectId }).pipe(
			map((res: { distinctCounts: DistinctCounts }) => {
				return res.distinctCounts;
			})
		);
	}

	/**
	 * Returns all distinct products in a projects boqs
	 * @param projectId
	 * @returns {Observable<Product[]>}
	 */
	public postDistinctProducts(projectId: string): Observable<Product[]> {
		return this.http.post<{ products: Product[] }>(`${Constants.BASE_API_URL}/project/list-product`, { projectId }).pipe(
			map((res: { products: Product[] }) => {
				return res.products;
			})
		);
	}

	/**
	 * Returns the list of filesFolders and attachments for the given project and folderKey.
	 * @param projectId
	 * @param folderKey
	 * @param folderName
	 * @param showMasterData
	 * @returns {Observable<ExportDocument[]>}
	 */
	public postExportsList(projectId: string, folderKey: string, folderName: string, showMasterData: boolean = true): Observable<ExportDocument[]> {
		return this.http.post<ExportDocumentsResponse>(`${Constants.BASE_API_URL}/project/exports-list`, { projectId, folderKey, folderName, showMasterData }).pipe(
			map((res: ExportDocumentsResponse) => {
				return res.items;
			})
		);
	}

	public getSafetySheets(projectId: string): Observable<ExportDocument[]> {
		return this.http.post<ExportDocumentsResponse>(`${Constants.BASE_API_URL}/project/safety-sheet-list`, { projectId }).pipe(
			map((res: ExportDocumentsResponse) => {
				return res.items;
			})
		);
	}

	/**
	 * Returns the list of mark up plans for the given project and folderKey.
	 * @param projectId
	 * @param folderKey
	 * @param folderName
	 * @returns {Observable<ExportDocument[]>}
	 */
	public postMarkUpPlans(projectId: string, folderKey: string, folderName: string, showMasterData: boolean = true): Observable<ExportDocument[]> {
		return this.http.post<ExportDocumentsResponse>(`${Constants.BASE_API_URL}/project/exports-list`, { projectId, folderKey, folderName, showMasterData }).pipe(
			map((res: ExportDocumentsResponse) => {
				return res.items;
			})
		);
	}

	/**
	 * Gets Projects for Project Search Dropdown
	 * @returns {Observable<Project>[]}
	 */
	public getProjectList(search: string): Observable<Project[]> {
		const postObj = {
			search,
		};

		return this.http.post<ProjectsResponse>(`${Constants.BASE_API_URL}/project/search-list`, postObj).pipe(
			map((res: ProjectsResponse) => {
				return res.projects;
			}),
			shareReplay(1)
		);
	}

	/**
	 * Gets All Projects for Project Search Dropdown
	 * @returns {Observable<Project>[]}
	 */
	public getFullProjectList(search: string): Observable<ProjectsFullListSearchResponse> {
		const postObj = {
			search,
		};

		return this.http.post<ProjectsFullListSearchResponse>(`${Constants.BASE_API_URL}/project/search-full-list`, postObj).pipe(shareReplay(1));
	}

	/**
	 * Gets Projects for pagination
	 * @returns {Observable<Project>[]}
	 */
	public postPaginate(paginate: { search: any; skip: number; limit: number, searchString?: string }, sort?: LibrarySort): Observable<ProjectSearchSummary> {
		const postObj = {
			...paginate,
			sort,
		};
		return this.http.post<ProjectsSearchResponse>(`${Constants.BASE_API_URL}/project/paginate`, postObj).pipe(
			map((res: ProjectsSearchResponse) => {
				return res.projects;
			}),
			shareReplay(1)
		);
	}

	/**
	 * Gets Live Projects for pagination
	 * @returns {Observable<Project>[]}
	 */
	public postPaginateLiveProjects(paginate: {
		clientId: string,
		projectName: string,
		projectRef: number,
		startDate: Date,
		endDate: Date,
		skip: number;
		limit: number,
	}): Observable<{ items: LiveProjectResult[], totals: LiveProjectTotals }> {
		return this.http.post<{ items: LiveProjectResult[], totals: LiveProjectTotals }>(`${Constants.BASE_API_URL}/project/paginate-live-projects`, paginate).pipe(
			shareReplay(1)
		);
	}

	/**
	 * Returns all suppliers in a projects boqs
	 * @param projectId
	 * @returns {Observable<EmbeddedSupplier[]>}
	 */
	public postSuppliers(projectId: string): Observable<EmbeddedSupplier[]> {
		return this.http.post<{ suppliers: EmbeddedSupplier[] }>(`${Constants.BASE_API_URL}/project/list-suppliers`, { projectId }).pipe(
			map((res: { suppliers: EmbeddedSupplier[] }) => {
				return res.suppliers;
			})
		);
	}

	/**
	 * Changes the isActive state of the given project, using id.
	 * @param {string} id
	 * @param {boolean} isActive
	 * @returns {Observable<Project>}
	 */
	public toggleProjectActiveState(id: string, isActive: boolean): Observable<Project> {
		return this.http
			.post<ProjectResponse>(`${Constants.BASE_API_URL}/project/edit-active`, {
				projectId: id,
				isActive: isActive,
			})
			.pipe(
				map((res: ProjectResponse) => {
					return res.project;
				})
			);
	}

	/**
	 * Post to set whether to show charges of the project by sub-group
	 * @param projectId
	 * @param showSubGroups
	 */
	public postShowSubGroups(projectId: string, showSubGroups: boolean): Observable<any> {
		return this.http.post<ProjectResponse>(`${Constants.BASE_API_URL}/project/show-subgroups`, { projectId, showSubGroups }).pipe(
			map((res: ProjectResponse) => {
				return new Project(res.project);
			})
		);
	}

	/**
	 * Post to unset isLegacyCalculation flag of a project
	 * @param projectId
	 */
	public unsetLegacyCalculationFlag(projectId: string): Observable<any> {
		return this.http.post<ProjectResponse>(`${Constants.BASE_API_URL}/project/unset-legacy-calculation-flag`, { projectId }).pipe(
			map((res: ProjectResponse) => {
				return new Project(res.project);
			})
		);
	}

	/**
	 * Retrieves all distributions for a project, grouped by product+colour and size
	 * @param projectId
	 */
	public getProjectDistribution(projectId: string) {
		return this.http.post<ItemsResponseData<DistributionSummaryModel>>(`${Constants.BASE_API_URL}/project/project-distribution`, { projectId }).pipe(map(res => res.items));
	}

	/**
	 * Triggers the upload of all project files to Sharepoint
	 * @param projectId
	 */
	public uploadSharepointProjectFiles(projectId: string) {
		return this.http.post<ItemsResponseData<DistributionSummaryModel>>(`${Constants.BASE_API_URL}/sharepoint/upload-project-files`, { projectId }).pipe(map(res => res));
	}
}

@Injectable()
export class GetProjectResolver {
	constructor(private projectService: ProjectService) {}

	public resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
		if (route.params.projectId) {
			return this.projectService.getProject(route.params.projectId);
		}
		if (route.queryParams.projectId) {
			return this.projectService.getProject(route.queryParams.projectId);
		}
		return undefined;
	}
}

@Injectable()
export class GetCloneProjectResolver {
	constructor(private projectService: ProjectService) {}

	public resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
		if (route.params.cloneProjectId) {
			return this.projectService.getProject(route.params.cloneProjectId);
		}
		if (route.queryParams.cloneProjectId) {
			return this.projectService.getProject(route.queryParams.cloneProjectId);
		}
		return undefined;
	}
}
