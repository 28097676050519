import { Validators } from '@angular/forms';

import { Constants, CustomValidators, ValidatorHelper } from '../helpers';
import { BoqModel, ChargeModel, EntityModel, UserModel, ProjectSessionModel, ShiftModel } from '../models';
import { InvoiceModel } from '../pages/project/invoice/common/invoice.models';
import { ProjectTotals } from '../pages/project/project-search/common/project-totals.interface';
import { VariationModel } from '../pages/project/variation/common/variation.models';
import { AdditionalCharge } from './additional-charge';
import { AddressDetail } from './address-detail';
import { Company, Contact } from './company';
import { Competitor } from './competitor';
import { LabourRate } from './labourRate';
import { ReferenceCode } from './reference-code';
import { Region } from './region';

export class LabourCostBase {
	public afterHoursRateEba: number;
	public afterHoursRateNonEba: number;
	public project: string;
	public rateEBA: number;
	public rateNonEBA: number;
	public heightAllowance?: number;
	public id?: string;
	public rateCost?: number;
	public siteAllowance?: number;
}

// Class for saving model to DB
export class LabourCostSaveObj extends LabourCostBase {
	public labourType?: string;

	constructor(labourCost: LabourCost) {
		super();
		// Replace the labourCost obj with ID
		const newLabourCost = Object.assign(labourCost, {
			labourType: labourCost?.labourType?.id ? labourCost.labourType.id : undefined,
		});
		Object.assign(this, newLabourCost);
	}
}

// Class for receiving model from the DB
export class LabourCost extends LabourCostBase {
	public labourType?: ReferenceCode;

	constructor() {
		super();
	}
}

export class ContactClient {
	public address?: string;
	public company?: Company;
	public contact?: Contact;
	public email?: string;
	public id?: string;
	public clientContactName?: string;

	constructor(obj: Partial<ContactClient>) {
		if (obj) {
			Object.assign(this, obj);
		}
		this.clientContactName = this.company.name + ' - ' + this.contact.name + ' - ' + this.email;
	}
}

export interface ProjectSearchSummary {
	items: Project[];
	totalCount: number;
	totalGPPercent: number;
	totalProfit: number;
	totalValue: number;
}

export interface ProjectSearchSummary {
	items: Project[];
	totalCount: number;
	totalGPPercent: number;
	totalProfit: number;
	totalValue: number;
}

class ProjectBase {
	public _id?: string;
	public additionalChargesChargeOut: number;
	public additionalChargesCost: number;
	public defaultChargeCategory: ReferenceCode;
	public discount: number;
	public discountPercentage: number;
	public discountType: string;
	public isActive: boolean;
	public isDiscountDisabled: boolean;
	public isDiscountPercentage: boolean;
	public isLegacyCalculation: boolean;
	public probability: number;
	public addressDetail?: AddressDetail = {}; // Site Address
	public isDeliveryAddressSame?: boolean = true;
	public isBillingAddressSame?: boolean = true;
	public averageTargetRate?: number;
	public billingAddressDetail?: AddressDetail = {};
	public chargeOrder?: Array<ChargeModel | BoqModel | AdditionalCharge>;
	public clients: ContactClient[] = [];
	public clientWonLost?: string;
	public commentsToEstimator?: string;
	public company?: Company;
	public completeCustomerProposal?: Date;
	public completeEstimate?: Date;
	public contractAdministrator?: UserModel;
	public createdAt?: Date;
	public decisionDate?: Date;
	public deliveryAddressDetail?: AddressDetail = {};
	public discountOption?: string;
	public division: string;
	public estimator?: UserModel;
	public exclusions?: string;
	public grossTotalChargeOut?: number;
	public grossTotalCost?: number;
	public gst?: number;
	public gstChargeOut?: number;
	public heightFeeChargeOut?: number;
	public id?: string; // id is optional, it should not be used when creating a new project
	public includeComments?: boolean;
	public inclusions?: string;
	public isEbaRates?: boolean;
	public labourChargeOut?: number;
	public labourCost?: number;
	public labourHoursTotal?: number;
	public leadReference?: string;
	public lostCompetitor?: Competitor;
	public lostDate?: Date; // set todays date when project status to lost
	public managementToReviewQuote?: Date;
	public materialMarkUp?: number;
	public materialsBasedOn?: string;
	public materialWasteChargeOut?: number;
	public materialWasteCost?: number;
	public name?: string;
	public netTotalChargeOut?: number;
	public operatingHours: any;
	public paymentTerms?: string;
	public projectFinishDate?: Date;
	public projectManager?: UserModel;
	public projectStartDate?: Date;
	public purchaseOrderNumber?: string;
	public quoteClientList?: ContactClient[];
	public quoteData?: any;
	public quoteDueDate?: Date; // set by the user
	public quoteFinishDate?: Date; // set by user
	public quoteStartDate?: Date; // set by the user
	public quoteTitle?: string;
	public reference?: number;
	public salesPerson?: UserModel;
	public industry?: string;
	public shifts?: ShiftModel[];
	public siteContact?: string;
	public siteEmail?: string;
	public sitePhone?: string;
	public status?: string;
	public statusDate?: Date; // The effective date of the current project status
	public statusOtherReason?: string;
	public statusReason?: string;
	public showSubGroups: boolean;
	public submittedDate?: Date; // set todays date when project status to submission date
	public submitToCustomer?: Date;
	public subIndustry?: string;
	public totalAccess?: number;
	public totalIncTax?: number;
	public totalLitre?: number;
	public totalMarginPercent?: number;
	public totalMaterial?: number;
	public totalOther?: number;
	public totalProfit: number;
	public totalProjectBuildValue?: number;
	public totalUnit?: number;
	public totalUnits?: number;
	public marketing?: string;
	public updatedAt?: Date;
	public variations?: VariationModel[];
	public wasteToConsumables?: number;
	public wonDate?: Date; // set todays date when project status to won date
	public invoices?: InvoiceModel[];
}

// Class for saving model to DB
export class ProjectSaveObj extends ProjectBase {
	public labourType?: string;

	constructor(project: Project) {
		super();
		// Replace the labourRate obj with ID
		const newProj = Object.assign({}, project, {
			labourType: project && project.labourType ? project.labourType.id : null,
		});
		Object.assign(this, newProj);
	}
}

// Class for receiving model from the DB
export class Project extends ProjectBase {
	public labourType?: LabourRate;
	public entity: EntityModel;

	constructor(obj?: Partial<Project>) {
		super();
		if (obj) {
			Object.assign(this, JSON.parse(JSON.stringify(obj)));
		}
		// Object.assign(this, obj);
		// this.clients = obj.clients.map(client => Object.assign({}, client));
		// this.clients = obj.clients.map(client => new ClientModel(client));
	}
}

//Interface for searching the project on dashboard
export interface ProjectSearch {
	addressLine1?: string;
	addressLine2?: string;
	addressLine3?: string;
	city?: string;
	client?: {
		name: string;
		id: string;
	};
	company?: string;
	contact?: string;
	contractAdministrator?: string;
	createdFrom?: Date;
	createdTo?: Date;
	dateFrom?: Date;
	dateTo?: Date;
	decisionFrom?: Date;
	decisionTo?: Date;
	division?: string;
	estimator?: string;
	salesPerson?: string;
	estimators?: string[];
	isActive?: boolean;
	leadReference?: string;
	limit?: number;
	materialsBasedOn?: string;
	name?: string;
	postalCode?: string;
	probabilityFrom?: number;
	probabilityTo?: number;
	projectFinishDate?: Date;
	projectStartDate?: Date;
	quoteDueFrom?: Date;
	quoteDueTo?: Date;
	quoteFinishFrom?: Date;
	quoteFinishTo?: Date;
	quoteSentDate?: Date;
	quoteStartFrom?: Date;
	quoteStartTo?: Date;
	reference?: number;
	region?: Region;
	siteContact?: string;
	state?: string;
	status?: string[];
	submitFrom?: Date;
	submitTo?: Date;
	wonFrom?: Date;
	wonTo?: Date;
	lostFrom?: Date;
	lostTo?: Date;
}

// Interface for searching project on project search page and charges page, it will be stored in local storage, we will need Id of project naviagated to
export interface ProjectSearchLocalStorage {
	values: ProjectSearch;
	displayValues: ProjectSearchDisplayValues;
}

// Interface for display values of project search to populate set values on init
export interface ProjectSearchDisplayValues {
	[key: string]: string | number | Date;
}

// Response type given back when retrieving a project
export interface ProjectResponse {
	project: Project;
	status: boolean;
}

// Project type given back when retrieving a project on global project search
export interface ProjectSearchItem {
	addressDetail: AddressDetail;
	_id: string;
	name: string;
	reference: number;
}

// Response type given back when retrieving a list of projects
export interface ProjectsResponse {
	projects: Project[];
	status: boolean;
}

export interface ProjectsFullListSearchResponse {
	projects: Project[];
	projectTotals: ProjectTotals;
	status: boolean;
}

export interface ProjectsSearchResponse {
	projects: ProjectSearchSummary;
	status: boolean;
}

export interface ProjectTargetingUpdate {
	id: string;
	commentsToEstimator?: string;
	estimator?: string;
}

export interface LabourCostResponse {
	labourCost: LabourCost;
	status: boolean;
}

export interface LabourCostsResponse {
	labourCosts: LabourCost[];
	project: Project;
	status: boolean;
}

export interface LabourCostsResponseData {
	labourCosts: LabourCost[];
	project: Project;
}

export interface ProjectSessionResponse {
	projectSession: ProjectSessionModel;
	message?: string;
}

export function GetAddressValidation(addressDetail?: AddressDetail, projectStatus?: string, isSiteAddress: boolean = false) {
	return ValidatorHelper.formBuilder([
		{
			name: 'Address1',
			validators: projectStatus === Constants.PROJECT_STATUS.won.value && isSiteAddress ? [Validators.maxLength(255), Validators.required] : [Validators.maxLength(255)],
			value: addressDetail?.addressLine1,
		},
		{
			name: 'Address2',
			validators: [Validators.maxLength(255)],
			value: addressDetail?.addressLine2,
		},
		{
			name: 'Address3',
			validators: [Validators.maxLength(255)],
			value: addressDetail?.addressLine3,
		},
		{
			name: 'City',
			validators: [Validators.maxLength(255)],
			value: addressDetail?.city,
		},
		{
			name: 'Country',
			validators: [Validators.required],
			value: addressDetail?.country,
		},
		{
			name: 'State',
			validators: [Validators.required],
			value: addressDetail?.state,
		},
		{
			name: 'Region',
			validators: [],
			value: addressDetail?.region,
		},
		{
			name: 'PostCode',
			validators: [Validators.maxLength(15)],
			value: addressDetail?.postCode,
		},
	]);
}

export function GetDeliveryAddressValidationWhenSameAsBilling(addressDetail?: AddressDetail, projectStatus?: string, isSiteAddress: boolean = false) {
	return ValidatorHelper.formBuilder([
		{
			name: 'Address1',
			validators: projectStatus === Constants.PROJECT_STATUS.won.value && isSiteAddress ? [Validators.maxLength(255), Validators.required] : [Validators.maxLength(255)],
			value: addressDetail?.addressLine1,
		},
		{
			name: 'Address2',
			validators: [Validators.maxLength(255)],
			value: addressDetail?.addressLine2,
		},
		{
			name: 'Address3',
			validators: [Validators.maxLength(255)],
			value: addressDetail?.addressLine3,
		},
		{
			name: 'City',
			validators: [Validators.maxLength(255)],
			value: addressDetail?.city,
		},
		{
			name: 'Country',
			value: addressDetail?.country,
		},
		{
			name: 'State',
			value: addressDetail?.state,
		},
		{
			name: 'Region',
			validators: [],
			value: addressDetail?.region,
		},
		{
			name: 'PostCode',
			validators: [Validators.maxLength(15)],
			value: addressDetail?.postCode,
		},
	]);
}

export function GetProjectValidation(project?: Project) {
	const validators = ValidatorHelper.formBuilder([
		{
			name: 'DefaultChargeCategory',
			validators: [Validators.required],
		},
		{
			name: 'Description',
			validators: [Validators.maxLength(255)],
		},
		{
			name: 'Company',
			validators: [Validators.required],
		},
		{
			name: 'Comment',
			validators: [Validators.maxLength(255)],
		},
		{
			name: 'Address',
			validators: [Validators.maxLength(255)],
		},
		{
			name: 'LeadReference',
			validators: [Validators.maxLength(255)],
		},
		{
			name: 'Probability',
			validators: [Validators.min(0), Validators.max(100)],
		},
		{
			name: 'Name',
			validators: [Validators.required, Validators.maxLength(255), CustomValidators.noWhiteSpaceValidator],
		},
		{
			name: 'SiteContact',
			validators: [Validators.maxLength(255)],
		},
		{
			name: 'PaymentTerms',
			validators: [Validators.required],
		},
		{
			name: 'MaterialMarkUp',
			validators: [Validators.maxLength(255), Validators.required],
		},
		{
			name: 'TotalProjectBuildValue',
			validators: [Validators.min(0)],
		},
		{
			name: 'CommentsToEstimator',
			validators: [Validators.maxLength(255)],
		},
		{
			name: 'WasteToConsumables',
			validators: [Validators.required, Validators.maxLength(255)],
		},
		{
			name: 'GST',
			validators: [
				Validators.required,
				Validators.maxLength(255),
				// Validators.max(5) //Incase GST has a limit
			],
		},
		{
			name: 'PurchaseOrderNumber',
			validators: [Validators.maxLength(255)],
		},
		{
			name: 'Inclusions',
			validators: [Validators.maxLength(3000)],
		},
		{
			name: 'Exclusions',
			validators: [Validators.maxLength(3000)],
		},
		{
			name: 'AttachmentsKey',
			validators: [Validators.maxLength(255)],
		},
		{
			name: 'AttachmentsName',
			validators: [Validators.maxLength(255)],
		},
		{
			name: 'AttachmentsCategory',
			validators: [Validators.maxLength(255)],
		},
		{
			name: 'DiscountType',
			validators: [
				Validators.maxLength(255),
				// Validators.required
			],
		},
		{
			name: 'ContactAddress',
			validators: [Validators.maxLength(255)],
		},
		{
			name: 'Estimator',
		},
		{
			name: 'SalesPerson',
			validators: [Validators.required],
		},
		{
			name: 'Division',
			validators: [Validators.required],
		},
		{
			name: 'LabourRate',
			validators: [Validators.required],
		},
		{
			name: 'Industry',
		},
		{
			name: 'SubIndustry',
		},
		// {
		//   name: 'LabourType',
		//   validators: [
		//     Validators.maxLength(255)
		//   ]
		// }
	]);

	return validators;
}

export function getSiteAddressEditStatusValidators() {
	return [
		{
			name: 'Address1',
			validators: [Validators.required, Validators.maxLength(255)],
		},
		{
			name: 'Address2',
			validators: [Validators.maxLength(255)],
		},
		{
			name: 'Address3',
			validators: [Validators.maxLength(255)],
		},
		{
			name: 'City',
			validators: [Validators.maxLength(255)],
		},
		{
			name: 'Country',
			validators: [Validators.required],
		},
		{
			name: 'State',
			validators: [Validators.required],
		},
		{
			name: 'Region',
			validators: [],
		},
		{
			name: 'PostCode',
			validators: [Validators.maxLength(15)],
		},
	];
}

export function GetProjectStatusValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Status',
			validators: [Validators.required],
		},
		{
			name: 'StatusDate',
			validators: [Validators.required],
		},
		{
			name: 'StatusReason',
			validators: [Validators.required],
		},
		{
			name: 'StatusOtherReason',
			validators: [Validators.required],
		},
		{
			name: 'Client',
			validators: [Validators.required],
		},
		{
			name: 'ContractAdministrator',
			validators: [Validators.required],
		},
		{
			name: 'ProjectManager',
			validators: [Validators.required],
		},
		{
			name: 'QuoteDueDate',
			validators: [Validators.required],
		},
		{
			name: 'SubmittedDate',
			validators: [Validators.required],
		},
		{
			name: 'StartDate',
			validators: [Validators.required],
		},
		{
			name: 'FinishDate',
			validators: [Validators.required],
		},
	]);
}

export function GetProjectTemplateValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Category',
			validators: [Validators.required],
		},
		{
			name: 'Revision',
			validators: [Validators.required, Validators.min(1)],
		},
	]);
}

export function GetProjectClientValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Client',
			validators: [Validators.required],
		},
		{
			name: 'ContactPerson',
			validators: [Validators.required],
		},
		{
			name: 'QuoteSubmissionEmail',
			validators: [Validators.required, Validators.maxLength(300), Validators.email],
		},
		{
			name: 'SubmissionPostalAddress',
			validators: [Validators.maxLength(300), Validators.minLength(0)],
		},
	]);
}

export function GetProjectSummaryDiscountValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Discount',
			validators: [Validators.min(0)],
		},
		{
			name: 'DiscountPercentage',
			validators: [Validators.min(0)],
		},
	]);
}

export function GetLabourRateValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: `NonEBA`,
			validators: [Validators.required],
		},
		{
			name: `EBA`,
			validators: [Validators.required],
		},
		{
			name: `AfterHoursCostNonEba`,
			validators: [Validators.required],
		},
		{
			name: `AfterHoursCostEba`,
			validators: [Validators.required],
		},
		{
			name: `SiteAllowance`,
			validators: [Validators.required],
		},
		{
			name: `HeightAllowance`,
			validators: [Validators.required],
		},
	]);
}

export function GetNewLabourRateValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: `LabourType`,
			validators: [Validators.required],
		},
		{
			name: `NonEBA`,
			validators: [Validators.required],
		},
		{
			name: `EBA`,
			validators: [Validators.required],
		},
		{
			name: `AfterHoursCostNonEba`,
			validators: [Validators.required],
		},
		{
			name: `AfterHoursCostEba`,
			validators: [Validators.required],
		},
		{
			name: `SiteAllowance`,
			validators: [Validators.required],
		},
		{
			name: `HeightAllowance`,
			validators: [Validators.required],
		},
	]);
}

export function GetDiscountOptionsValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: `ProjectTotal`,
			validators: [],
		},
		{
			name: `BOQEvenly`,
			validators: [],
		},
		{
			name: `BOQIndividually`,
			validators: [],
		},
	]);
}
