import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Constants } from '../helpers';
import { ItemResponseData, ItemsResponseData } from '../interfaces';
import { PurchaseOrderModel } from '../models';

@Injectable()
export class PurchaseOrderService {
	constructor(private http: HttpClient) {}

	/**
	 * Gets a single purchaseOrder for provided purchaseOrder id
	 * @param id
	 * @param projectId
	 */
	public postDetails(id: string, projectId: string) {
		return this.http.post<ItemResponseData<PurchaseOrderModel>>(`${Constants.BASE_API_URL}/project/purchase-order/details`, { id, projectId }).pipe(map(res => res.item));
	}

	/**
	 * Gets a list of active purchaseOrders for the provided project
	 * @param projectId
	 */
	public postList(projectId: string) {
		return this.http.post<ItemsResponseData<PurchaseOrderModel>>(`${Constants.BASE_API_URL}/project/purchase-order/list`, { projectId }).pipe(map(res => res.items));
	}

	/**
	 * Creates a new purchaseOrder
	 * @param purchaseOrder
	 * @param projectId
	 */
	public createPurchaseOrder(purchaseOrder: PurchaseOrderModel, projectId: string) {
		return this.http
			.post<ItemResponseData<PurchaseOrderModel>>(`${Constants.BASE_API_URL}/project/purchase-order/create`, {
				purchaseOrder,
				projectId,
			})
			.pipe(map(res => res.item));
	}

	/**
	 * Updates a purchaseOrder's isActive state
	 * @param id
	 * @param isActive
	 * @param projectId
	 */
	public editActive(id: string, isActive: boolean, projectId: string): Observable<PurchaseOrderModel> {
		return this.http
			.post<ItemResponseData<PurchaseOrderModel>>(`${Constants.BASE_API_URL}/project/purchase-order/edit-active`, {
				id,
				isActive,
				projectId,
			})
			.pipe(map(res => new PurchaseOrderModel(res.item)));
	}

	/**
	 * Updates purchaseOrder
	 * @param id
	 * @param isReceived
	 * @param projectId
	 */
	public editReceived(id: string, isReceived: boolean, projectId: string) {
		return this.http
			.post<ItemResponseData<PurchaseOrderModel>>(`${Constants.BASE_API_URL}/project/purchase-order/edit-received`, {
				id,
				isReceived,
				projectId,
			})
			.pipe(map(res => res.item));
	}

	/**
	 * Retrieves a project's cost to date
	 * @param projectId
	 */
	public getProjectPurchaseOrderTotal(projectId: string): Observable<number> {
		return this.http.post<{ projectTotal: number }>(`${Constants.BASE_API_URL}/project/purchase-order/project-total`, { projectId }).pipe(map(res => res.projectTotal));
	}

	public setSentDate(id: string, sentDate: Date, projectId: string): Observable<PurchaseOrderModel> {
		return this.http
			.post<ItemResponseData<PurchaseOrderModel>>(`${Constants.BASE_API_URL}/project/purchase-order/set-sent-date`, {
				id,
				sentDate,
				projectId
			})
			.pipe(map(res => res.item));
	}
}
