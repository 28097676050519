import { Validators } from '@angular/forms';

import { CALCULATION_TYPES, Constants } from '../helpers';
import { ValidatorHelper } from '../helpers';
import { EmbeddedSupplierRateModel, ColourModel } from '../models';
import { Product } from './product';
import { LabourCost, Project } from './project';
import { ReferenceCode } from './reference-code';
import { LabourRate } from './labourRate';

// LabourRate object
export interface AdditionalCharge {
	attachments: string[];
	boq?: string;
	calculationType: string;
	chargeCategory: ReferenceCode;
	chargeType: string;
	colour?: ColourModel;
	comment: string;
	description: string;
	discount: number;
	discountPercentage: number;
	division: string;
	embeddedSupplierRate: EmbeddedSupplierRateModel;
	environment: string;
	fileKeys: string[];
	grossTotalChargeOut: number;
	isActive: boolean;
	isDiscountPercentage: boolean;
	isExportHidden: boolean;
	isSelected: boolean;
	labourHoursTotal?: number;
	labourType?: LabourRate | LabourCost;
	margin: number;
	markUp: number;
	overtimeRate?: number;
	product?: Product;
	project: string;
	rate?: number;
	requiredPaintUnit?: number;
	subLocation1: string;
	totalCharge: number;
	totalCost: number;
	totalLitre?: number;
	variation?: string;
	id?: string;
}

//Response type given back when retrieving a additionalCharge
export interface AdditionalChargeResponse {
	additionalCharge: AdditionalCharge;
	status: boolean;
}

export interface AdditionalChargeUpdateData {
	additionalCharge: AdditionalCharge;
	project: Project;
}

export interface AdditionalChargeAttachment {
	id: string;
	key: string;
	name: string;
}

export interface AdditionalChargeAttachmentsResponse {
	attachments: AdditionalChargeAttachment[];
	status: boolean;
}

export function GetAdditionalChargeValidation(additionalCharge: AdditionalCharge) {
	let formValidations: any[] = [
		{
			name: 'Discount',
			validators: [Validators.min(0)],
			value: additionalCharge.discount,
		},
		{
			name: 'Division',
			validators: [Validators.required],
			value: additionalCharge.division,
		},
		{
			name: 'Description',
			validators: [Validators.required, Validators.maxLength(300)],
			value: additionalCharge.description,
		},
		{
			name: 'SubLocation1',
			validators: [Validators.required, Validators.maxLength(300)],
			value: additionalCharge.subLocation1,
		},
		{
			name: 'Comment',
			validators: [Validators.maxLength(3000)],
			value: additionalCharge.comment,
		},
		{
			name: 'ChargeCategory',
			validators: [Validators.required, Validators.maxLength(300)],
			value: additionalCharge.chargeCategory,
		},
		{
			name: 'Environment',
			validators: [Validators.required, Validators.maxLength(300)],
			value: additionalCharge.environment,
		},
		{
			name: 'Markup',
			validators: [Validators.required],
			value: additionalCharge.markUp,
		},
		{
			name: 'Rate',
			validators: [Validators.required],
			value: additionalCharge.rate,
		},
		{
			name: 'Hours',
			validators: [Validators.required],
			value: additionalCharge.labourHoursTotal,
		},
	];

	// Add charge category specific but calculation type generic validators
	if (additionalCharge.chargeCategory && additionalCharge.chargeCategory.title === Constants.CHARGE_CATEGORIES.overtimeHours) {
		formValidations.push({
			name: 'BOQ',
			validators: [Validators.required],
			value: additionalCharge.boq,
		});
	} else if (additionalCharge.chargeCategory && additionalCharge.chargeCategory.title === Constants.CHARGE_CATEGORIES.materials) {
		formValidations.push({
			name: 'Product',
			validators: [Validators.required],
		});
	}

	// Add remaining validators based on calculation type
	if (additionalCharge.calculationType === CALCULATION_TYPES.cost) {
		switch (additionalCharge.chargeCategory && additionalCharge.chargeCategory.title) {
			case Constants.CHARGE_CATEGORIES.overtimeHours:
				formValidations = formValidations.filter(field => field.name !== 'Hours'); // remove validation for hours field, we will add overtimeHours instead
				formValidations.push(
					{
						name: 'OvertimeHours',
						validators: [Validators.required],
					},
					{
						name: 'OvertimeRate',
						validators: [Validators.required],
					}
				);
				break;

			case Constants.CHARGE_CATEGORIES.materials:
				formValidations = formValidations.filter(field => field.name !== 'Hours' && field.name !== 'Rate');
				formValidations.push({
					name: 'AmountLitres',
					validators: [Validators.required],
				});
		}
	} else if (additionalCharge.calculationType === CALCULATION_TYPES.lumpSum) {
		formValidations = formValidations.filter(field => {
			return field.name !== 'Hours' && field.name !== 'Rate' && field.name !== 'Discount';
		});
	} else if (additionalCharge.calculationType === CALCULATION_TYPES.supplier) {
		formValidations = formValidations.filter(field => field.name !== 'Hours' && field.name !== 'Rate' && field.name !== 'Markup');
		formValidations.push(
			{
				name: 'Supplier',
				validators: [Validators.required],
				value: additionalCharge.embeddedSupplierRate.supplier,
			},
			{
				name: 'SupplierProduct',
				validators: [Validators.required],
				value: additionalCharge.embeddedSupplierRate.name,
			}
		);
	}

	return ValidatorHelper.formBuilder(formValidations);
}
