// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-tool-modal {
  min-height: 400px;
  max-height: 900px;
  width: 500px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/estimator-user-tool-library/estimator-user-tool-library.component.scss"],"names":[],"mappings":"AAAA;EACC,iBAAA;EACA,iBAAA;EACA,YAAA;AACD","sourcesContent":[".user-tool-modal {\n\tmin-height: 400px;\n\tmax-height: 900px;\n\twidth: 500px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
