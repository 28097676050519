import { CurrencyPipe } from '@angular/common';
import { ChangeDetectorRef, Component, DestroyRef, ElementRef, EventEmitter, inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { faChevronDown, faChevronUp, faCommentAlt, faRuler, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Decimal } from 'decimal.js';

import { finalize, Observable, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { ContentCardModel } from '../../../../@itc-core/components/components/content-card/common/content-card.models';
import { NavigationWarningService } from '../../../../@itc-core/components/navigation-warning/common/navigation-warning.service';
import { ItcModalConfig } from '../../../../@itc-core/directives/modal/common/modal.models';
import { InputNumberConfig } from '../../../core/components/display-input-number/common/display-input-number.models';
import { CALCULATION_TYPES, Constants, ERROR_MESSAGES, SnackBarHelperComponent, ValidatorHelper } from '../../../helpers';
import { BoqUpdateData, Paginate, Project, ReferenceCode } from '../../../interfaces';
import { BoqModel, ChargeModel, GetAdditionalChargeChargesValidation, GetBoqChargesValidation, UserModel } from '../../../models';
import { MeasuringToolLayoutDataService } from '../../../pages/measure-tool/common/components/measuring-tool-layout/common/measuring-tool-layout.data.service';
import { ProjectRouteData } from '../../../pages/project/common/project.interfaces';
import { ProjectRouteService } from '../../../pages/project/common/project.service';
import { CalculationService } from '../../../services/calculation.service';
import { ChargeService } from '../../../services/charge.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { ModalService } from '../../../services/modal.service';
import { NavigationService } from '../../../services/navigation.service';
import { ProjectService } from '../../../services/project.service';
import { RefCodeService } from '../../../services/reference-code.service';
import { RolePermissionsService } from '../../../services/role-permissions.service';
import { ScrollService } from '../../../services/scroll-service';
import {
	BulkBoqEditDialogComponent,
	CoatingSystemEditDialogComponent,
	ColourEditDialogComponent,
	CommentsEditDialogComponent,
	LocationEditDialogComponent,
	SubstrateEditDialogComponent,
} from '../index';
import { ChargesConstants } from './common/charges.constants';
import { ChargesTableData, DropdownData, SubGroup } from './common/charges.interfaces';
import { ChargesService } from './common/charges.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PopoverComponent } from '../../../../@mos-core/components/popover/popover.component';
import { DropdownOption } from '../../../pages/project/components/project-page-footer/project-page-footer.component';
import { MeasureSystemService } from '../../../services/measure-system.service';
import { RatePipe } from '../../../pipes/rate.pipe';

@Component({
	selector: 'paint-charges',
	templateUrl: './charges.component.html',
	styleUrls: ['./charges.component.scss'],
})
export class ChargesComponent implements OnInit, OnDestroy {
	@ViewChild('editCoatingSystem', { static: true })
	public editCoatingSystemComponent: CoatingSystemEditDialogComponent;

	@ViewChild(BulkBoqEditDialogComponent, { static: false })
	public bulkBoqEditDialogComponent: BulkBoqEditDialogComponent;

	@ViewChild('locationEditDialog', { static: true })
	public locationEditRef: ElementRef;
	@ViewChild(LocationEditDialogComponent, { static: false })
	public locationEditDialogComponent: LocationEditDialogComponent;

	@ViewChild(CommentsEditDialogComponent)
	public commentsEditDialogComponent: CommentsEditDialogComponent;

	@ViewChild('substrateEditDialog', { static: true })
	public substrateEditRef: ElementRef;
	@ViewChild(SubstrateEditDialogComponent, { static: false })
	public substrateEditDialogComponent: SubstrateEditDialogComponent;

	@ViewChild('colourEditDialog', { static: true })
	public colourEditRef: ElementRef;
	@ViewChild(ColourEditDialogComponent, { static: false })
	public colourEditDialogComponent: ColourEditDialogComponent;

	@ViewChild('actionMenuPopover') public actionMenuPopover!: PopoverComponent;

	@Input()
	public set chargesTableData(chargesTableData: ChargesTableData) {
		if (chargesTableData) {
			this._chargesTableData = chargesTableData;
			this.charges = chargesTableData.charges;
			this.chargeTotals = chargesTableData.totals;
			this.setIsAssociatedToMeasuringToolPage();
			this.updateValidators(this.charges);
			this.setSubGroupArray();
			this.updateTotalCount();
			this.isAllChargesSelected = false;
			this.isAnyChargeSelected = false;
			this.isLoaded = true;
			this.scrollToBoq();
		}
	}
	public get chargesTableData() {
		return this._chargesTableData;
	}

	@Input()
	public set isChargeDiscountDisabled(value: boolean) {
		this._isChargeDiscountDisabled = value;
		if (this.charges && this.charges.length) {
			this.updateValidators(this.charges);
		}
	}
	public get isChargeDiscountDisabled() {
		return this._isChargeDiscountDisabled;
	}

	@Output()
	public deselectTabEmitter: EventEmitter<void> = new EventEmitter<void>();
	@Output()
	public updateCharges: EventEmitter<Paginate> = new EventEmitter<Paginate>();
	@Output()
	public updateProjectDetailsTabs: EventEmitter<void> = new EventEmitter();

	public additionalChargeType = Constants.CHARGE_TYPES.AdditionalCharge;
	public measuringToolBoqIds: string[] = [];
	public bulkEditCloneModalConfig: ItcModalConfig = new ItcModalConfig({
		showCloseModalButton: true,
		title: ChargesConstants.bulkEdit,
	});
	public calculateBoqSubscription: Subscription;
	public calculationTypes: typeof CALCULATION_TYPES = CALCULATION_TYPES;
	public cardConfig: ContentCardModel = new ContentCardModel({
		fillHeight: false,
	});
	// A 200ms delay is used on the charges table ngBusy so that the user can click directly from an edited input field
	// to the save project button without the busy blocking it
	public chargeBusyDelay: number = 200;
	public charges: ChargeModel[] = [];
	public chargeOrderSubscription: Subscription;
	public chargeTotals = {
		totalLabourHours: 0,
		totalUnits: 0,
		averageCostPerUnits: 0,
		totalCosts: 0,
		averageRatePerUnits: 0,
		totalHeightFee: 0,
		totalChargeOut: 0,
		totalDiscounts: 0,
		totalCount: 0,
		totalProfit: 0,
		totalGP: 0,
	};
	public chargeTypes = Constants.CHARGE_TYPES;
	public chevronDown: IconDefinition = faChevronDown;
	public chevronUp: IconDefinition = faChevronUp;
	public coatingSystems: Array<string>;
	public comment: IconDefinition = faCommentAlt;
	public currentPageNo: number;
	public currentUser: UserModel;
	public dropdownOptions: DropdownOption[];
	public editBoqSubscription: Subscription;
	public editCoatingSystemModalConfig: ItcModalConfig = new ItcModalConfig({
		showCloseModalButton: true,
		title: ChargesConstants.editCoatingSystem,
		confirmButtonText: ChargesConstants.saveChanges,
	});
	public environments: Array<string> = Constants.ENVIRONMENTS;
	public environmentsMap = {};
	public environmentsMapTotals = {};
	public fetchCharges: Promise<Project>;
	public filterEnvironment: string;
	public filterIsActive = true;
	public filterCoatingSystem: string;
	public filterSubLocation1: string;
	public filterSubLocation2: string;
	public filterSubLocation3: string;
	public filterSubstrate: string;
	public grandTotalsText: string = ChargesConstants.grandTotals;
	public hasMeasuringToolAccess: boolean = false;
	public inputNumberConfig: InputNumberConfig = new InputNumberConfig({
		removePadding: true,
	});
	public isAllChargesSelected: boolean = false;
	public isAllSubGroupsExpanded: boolean = false;
	public isAnyChargeSelected: boolean = false;
	public isCloning: boolean = false;
	public isCreateAllowed: boolean = false;
	public isEditAllowed: boolean = false;
	public isEditOrder = false;
	public isLoaded = false;
	public isRequestingSave: boolean = false;
	public isSavingCoatingSystem: boolean = false;
	public isSelectAdditionalCharge = false;
	public isSelectBoq = false;
	public isUserKeyRole: boolean = false;
	public isUserLocked: boolean = true;
	public lastEditedCharge: string;
	public legacyCalculationOverrideModal: ItcModalConfig;
	public measurementAbbreviation = Constants.measurementAbbreviation;
	public measuringToolBoqIdsObservable: Observable<string[]>;
	public modalConfig: ItcModalConfig;
	public paginationLimit: number = 0;
	public projectFilterSubscription: Subscription;
	public projectId: string;
	public project: Project;
	public projectLoader: Observable<any>; //does not matter
	public referenceCodeTypes: ReferenceCode;
	public rulerIcon: IconDefinition = faRuler;
	public selectedCharge: ChargeModel | BoqModel;
	public selectedCommentCharge: ChargeModel;
	public selectedItems: string[];
	public selectedTab = true;
	public showBulkEditCloneModal: boolean = false;
	public showEditCoatingSystemModal: boolean = false;
	public showFilterOptions: boolean = false;
	public showLegacyCalculationOverrideModal: boolean = false;
	public showCommentModal: boolean = false;
	public showSubGroups: boolean = false;
	public subGroupArray: Array<SubGroup>;
	public subLocations1: Array<string>;
	public subLocations2: Array<string>;
	public subLocations3: Array<string>;
	public subscription: Subscription = new Subscription();
	public substrates: ReferenceCode[];
	public SVG_ICON_PATHS = Constants.SVG_ICON_PATHS;
	public textConstants: typeof ChargesConstants = ChargesConstants;
	public totalCount: number;
	public totalPages: number;
	public validators: any[];
	public variationId: string;
	public viewSelections = [this.textConstants.hideInactive, this.textConstants.showAll];
	public viewSelection = this.viewSelections[0];
	public isTraineeAndAbove: boolean = false;

	private _chargesTableData: ChargesTableData;
	private _isChargeDiscountDisabled = false;
	private scrollToBoqId: string;
	private subGroupsExpanded: string[] = [];
	private destroyRef = inject(DestroyRef);

	constructor(
		private calculationService: CalculationService,
		private chargeService: ChargeService,
		private chargesService: ChargesService,
		private currencyPipe: CurrencyPipe,
		private localStorage: LocalStorageService,
		private measuringToolService: MeasuringToolLayoutDataService,
		private modalService: ModalService,
		private navigationService: NavigationService,
		private navigationWarningService: NavigationWarningService,
		private projectRouteService: ProjectRouteService,
		private projectService: ProjectService,
		private refCodeService: RefCodeService,
		private rolesPermissions: RolePermissionsService,
		private scrollService: ScrollService,
		private snack: SnackBarHelperComponent,
		private changeDetectorRef: ChangeDetectorRef,
		private measuringToolLayoutDataService: MeasuringToolLayoutDataService,
		private measureSystemService: MeasureSystemService,
		private ratePipe: RatePipe,
	) {}

	public ngOnInit() {
		// get BoqId to scroll to, then reset it in the service
		this.scrollToBoqId = this.chargesService.currentChargeId;
		this.setLastEditedCharge(this.scrollToBoqId);
		this.chargesService.currentChargeId = '';
		this.isTraineeAndAbove = this.rolesPermissions.isTraineeAndAbove();
		this.navigationWarningService.setShowSaveAndLiveButton(false);

		this.projectLoader = this.projectRouteService.projectDataStream.pipe(
			tap((routeData: ProjectRouteData) => {
				if (!routeData) {
					return;
				}
				this.project = new Project(routeData.project);
				this.showSubGroups = this.project.showSubGroups;
				this.projectId = this.project.id;
				this.currentUser = routeData.currentUser;
				this.hasMeasuringToolAccess = routeData.currentUser.entity?.hasMeasuringToolAccess;
				this.isUserKeyRole = routeData.isUserKeyRole;
				this.isUserLocked = routeData.isUserLocked;
				this.variationId = routeData.variationId;
				this.setPermissions();
				this.initialisePage(this.projectId);
				this.setChargesFilter();
				this.setMeasuringToolBoqIds(this.projectId);
			}),
			switchMap(() => {
				return this.refCodeService.getRefCodesWithTypesAndStatus(['labour_type', 'divisions', 'profile', 'substrate'], true);
			}),
			tap((refCodeTypes: ReferenceCode) => {
				this.referenceCodeTypes = refCodeTypes;
			})
		);

		this.setModalConfig();
		this.initialiseDropdownOptions();

		this.measureSystemService.preferredSystem.pipe(
			takeUntilDestroyed(this.destroyRef),
		).subscribe(() => {
			this.paginateRefresh();
		});
	}

	public ngOnDestroy(): void {
		this.clearFilterFields();
		this.subscription.unsubscribe();
	}

	public calculateChargeOutFromProfit(boq: BoqModel) {
		this.calculateLabourRates(Constants.CHARGE_OUT_TYPES.profit, boq);
	}

	public calculateChargeOutFromRate(boq: BoqModel) {
		this.navigationWarningService.setStatus(true);
		// In the case there is a state set, manually adjusting rate in the table should clear it
		boq.coatingSystem.currentChargeOutState = undefined;

		if (new Decimal(boq.difficulty).greaterThanOrEqualTo(100)) {
			boq.difficulty = 99.99;
		}

		this.calculateLabourRates(Constants.CHARGE_OUT_TYPES.rate, boq);
	}

	public calculateChargeOutFromMargin(boq: BoqModel) {
		this.calculateLabourRates(Constants.CHARGE_OUT_TYPES.margin, boq);
	}

	public calculateChargeOutFromMarginAdditionalCharge(boq: BoqModel) {
		this.calculateLabourRatesAdditionalCharge(boq);
	}

	public calculateLabourRatesAdditionalCharge(charge: ChargeModel) {
		if (charge.coatingSystem) {
			if (charge.labourCost) {
				if (this.projectId && charge && charge.coatingSystem) {
					this.calculateBoqSubscription = this.calculationService
						.postGpFromAdditionalChargeCalculate(charge, this.projectId)
						.pipe(
							finalize(() => {
								this.finalizeCalculation();
							})
						)
						.subscribe((chargeItem: any) => {
							const index = this.charges.findIndex(item => item.id === chargeItem.id);

							if (index !== -1) {
								Object.assign(this.charges[index], new ChargeModel(chargeItem));
							}
							this.setSubGroupArray();
						});
				}
			}
		}
	}

	/**
	 * Toggle the isEditOrder property
	 */
	public cancelEditChargeOrder() {
		this.isEditOrder = false;
		this.refreshList();
		this.updateCharges.emit(this.getPaginateObject(1, 0, false));
	}

	public clearFilterFields(): void {
		this.filterSubstrate = undefined;
		this.filterEnvironment = undefined;
		this.filterSubLocation1 = undefined;
		this.filterSubLocation2 = undefined;
		this.filterSubLocation3 = undefined;
		this.filterCoatingSystem = undefined;
		this.viewSelection = this.viewSelections[0];
		this.toggleInactive();
		this.localStorage.removeChargesFilter();
		this.refreshList();
	}

	public changeMarkup(charge: ChargeModel) {
		this.navigationWarningService.setStatus(true);

		if (charge.chargeType === this.chargeTypes.AdditionalCharge) {
			this.calculateBoqSubscription = this.calculationService
				.postMarkupFromAdditionalChargeCalculate(charge, this.projectId)
				.pipe(
					finalize(() => {
						this.finalizeCalculation();
					})
				)
				.subscribe((chargeItem: ChargeModel) => {
					const index = this.charges.findIndex(item => item.id === chargeItem.id);

					if (index !== -1) {
						// Object.assign is needed to to fix strange behaviour in Chrome (scrolling one line after hitting enter)
						Object.assign(this.charges[index], new ChargeModel(chargeItem));
					}
					this.setSubGroupArray();
				});
		} else if (charge.chargeType === this.chargeTypes.Boq) {
			this.calculateBoqSubscription = this.calculationService
				.postRateFromBoqChargeCalculate(charge, this.projectId)
				.pipe(
					finalize(() => {
						this.finalizeCalculation();
					})
				)
				.subscribe((chargeItem: ChargeModel) => {
					const index = this.charges.findIndex(item => item.id === chargeItem.id);

					if (index !== -1) {
						Object.assign(this.charges[index], new ChargeModel(chargeItem));
					}
					this.setSubGroupArray();
				});
		}
	}

	public changeDiscount(charge: ChargeModel) {
		this.navigationWarningService.setStatus(true);

		charge.isDiscountPercentage = false;
		if (charge.chargeType === this.chargeTypes.Boq) {
			this.calculateChargeOutFromRate(charge as BoqModel);
		} else {
			this.calculateBoqSubscription = this.calculationService
				.postAdditionalChargeCalculate(charge.getPostObject(), this.projectId)
				.pipe(
					finalize(() => {
						this.finalizeCalculation();
					})
				)
				.subscribe((chargeItem: ChargeModel) => {
					const index = this.charges.findIndex(item => item.id === chargeItem.id);

					if (index !== -1) {
						Object.assign(this.charges[index], new ChargeModel(chargeItem));
					}
					this.setSubGroupArray();
				});
		}
	}

	public changeLabourHoursTotal(charge: ChargeModel): void {
		this.navigationWarningService.setStatus(true);

		if (charge.chargeType === this.chargeTypes.AdditionalCharge) {
			this.calculateBoqSubscription = this.calculationService
				.postAdditionalChargeCalculate(charge.getPostObject(), this.projectId)
				.pipe(
					finalize(() => {
						this.finalizeCalculation();
					})
				)
				.subscribe((chargeItem: ChargeModel) => {
					const index = this.charges.findIndex(item => item.id === chargeItem.id);

					if (index !== -1) {
						Object.assign(this.charges[index], new ChargeModel(chargeItem));
					}
					this.setSubGroupArray();
				});
		} else if (charge.chargeType === this.chargeTypes.Boq) {
			this.calculateBoqSubscription = this.calculationService
				.postRecalculateProductionRatesFromLabourHours(charge, this.projectId)
				.pipe(
					finalize(() => {
						this.finalizeCalculation();
					})
				)
				.subscribe((chargeItem: ChargeModel) => {
					const index = this.charges.findIndex(item => item.id === chargeItem.id);

					if (index !== -1) {
						Object.assign(this.charges[index], new ChargeModel(chargeItem));
					}
					this.setSubGroupArray();
				});
		}
	}

	public changeTotalCost(charge: ChargeModel): void {
		this.navigationWarningService.setStatus(true);

		this.calculateBoqSubscription = this.calculationService
			.postRecalculateAdditionalChargeWithNewCost(charge, this.projectId)
			.pipe(
				finalize(() => {
					this.finalizeCalculation();
				})
			)
			.subscribe((chargeItem: ChargeModel) => {
				const index = this.charges.findIndex(item => item.id === chargeItem.id);

				if (index !== -1) {
					Object.assign(this.charges[index], new ChargeModel(chargeItem));
				}
				this.setSubGroupArray();
			});
	}

	/**
	 * navigate to create charge for the project
	 */
	public createNewCharge(): void {
		this.setSubGroupsExpanded();
		this.chargesService.currentChargeId = undefined;
		this.navigationService.setRoute([`/project/${this.projectId}/charge`]);
	}

	public finalizeCalculation(): void {
		this.calculateBoqSubscription.unsubscribe();
		if (this.isRequestingSave) {
			this.updateProjectCharges();
		}
	}

	/**
	 * Returns a tooltip to show for the charges table Cost/Unit column
	 * @param boq
	 */
	public getTooltipFromBOQ(boq): string {
		const labourCost = this.currencyPipe.transform(boq.labourCostPerUnit);
		const materialCost = this.currencyPipe.transform(boq.materialCostPerUnit);
		const wasteCost = this.currencyPipe.transform(boq.wasteCostPerUnit);

		return `Labour cost: ${labourCost}
        Material cost: ${materialCost}
        Waste cost: ${wasteCost}`;
	}

	public setLastEditedCharge(chargeId): void {
		this.lastEditedCharge = chargeId;
	}

	public async newSubmitLogic(): Promise<void> {
		const bulkBoq = await this.bulkBoqEditDialogComponent.onSave();
		if (!bulkBoq) {
			return;
		}

		let boqUpdateObservable;

		if (this.isCloning) {
			boqUpdateObservable = this.chargeService.postBulkClone(this.selectedItems, bulkBoq, this.projectId).pipe(
				finalize(() => {
					this.editBoqSubscription.unsubscribe();
				})
			);
		} else {
			if (bulkBoq.bulkBoq.subLocation2) {
				this.measuringToolLayoutDataService
					.updateUserToolName(this.projectId, [...this.selectedItems], bulkBoq.bulkBoq.subLocation2)
					.pipe(takeUntilDestroyed(this.destroyRef))
					.subscribe();
			}

			boqUpdateObservable = this.chargeService.postBulkEdit(this.selectedItems, bulkBoq, this.projectId).pipe(
				finalize(() => {
					this.editBoqSubscription.unsubscribe();
				})
			);
		}

		this.editBoqSubscription = boqUpdateObservable.subscribe(bulkEditData => {
			this.isSelectBoq = false;
			this.isSelectAdditionalCharge = false;
			// When cloning charges to a different project, the target project is returned so we don't setProject() unless it's this project
			if (bulkEditData.project.id === this.projectId) {
				this.projectRouteService.setProject(bulkEditData.project);
				this.paginateRefresh();
			}
		});

		this.paginateRefresh();
		this.isCloning = false;
		this.showBulkEditCloneModal = false;
	}

	/**
	 * activate BOQs
	 */
	public onActivate(): void {
		const selectedItems = [];
		this.charges.forEach(charge => {
			if (charge.isSelected === true) {
				selectedItems.push(charge.id);
			}
		});
		if (selectedItems.length === 0) {
			this.snack.snackError(ERROR_MESSAGES.pleaseSelectCharge);
			return;
		}
		this.fetchCharges = new Promise((resolve, reject) => {
			this.subscription.add(
				this.chargeService.postEditBulkActive(selectedItems, true, this.projectId).subscribe(newProject => {
					this.paginateRefresh();
					this.projectRouteService.setProject(newProject);
					resolve(newProject);
				}, reject)
			);
		});
	}

	/**
	 * clone BOQs
	 */
	public onBulkClone(): void {
		this.bulkEditCloneModalConfig = new ItcModalConfig({
			showCloseModalButton: true,
			title: ChargesConstants.cloneCharges,
		});
		this.isCloning = true;
		this.selectedItems = this.getSelectedItems();

		if (this.selectedItems.length === 0) {
			this.snack.snackError(ERROR_MESSAGES.pleaseSelectCharge);
			return;
		}

		// this.showEditModal(selectedItems, isCloning);
		this.showBulkEditCloneModal = true;
	}

	/**
	 * edit BOQs
	 */
	public onBulkEdit() {
		this.bulkEditCloneModalConfig = new ItcModalConfig({
			showCloseModalButton: true,
			title: ChargesConstants.bulkEdit,
		});
		this.isCloning = false;
		this.selectedItems = this.getSelectedItems();

		if (this.selectedItems.length === 0) {
			this.snack.snackError(ERROR_MESSAGES.pleaseSelectCharge);
			return;
		}
		// this.showEditModal(selectedItems, isCloning);

		this.showBulkEditCloneModal = true;
	}

	/**
	 * Called when a charge is selected. Updates UI logic variables.
	 * Loop through all charges to determine which are selected
	 */
	public onChargeSelected() {
		this.isAllChargesSelected = true;
		this.isAnyChargeSelected = false;
		for (const subGroup of this.subGroupArray) {
			let areAllSubGroupChargesSelected = true;
			for (const charge of subGroup.items) {
				if (!charge.isSelected) {
					areAllSubGroupChargesSelected = false;
					this.isAllChargesSelected = false;
				} else {
					this.isAnyChargeSelected = true;
				}
			}
			subGroup.isSelected = areAllSubGroupChargesSelected;
		}
	}

	/**
	 * When a charge is clicked from the table, navigate to it.
	 * Save what sub-groups are expanded at this time.
	 * @param charge
	 */
	public onChargeClicked(charge: ChargeModel) {
		this.setSubGroupsExpanded();
		this.chargesService.currentChargeId = charge.id;

		if (charge.chargeType === this.chargeTypes.Boq) {
			this.navigationService.setRoute([`/project/${charge.project}/boq/${charge.id}`]);
		} else if (charge.chargeType === this.chargeTypes.AdditionalCharge) {
			this.navigationService.setRoute([`/project/${charge.project}/additionalcharge/${charge.id}`]);
		}
	}

	/**
	 * deactivate BOQs
	 */
	public onDeactivate() {
		const selectedItems = [];
		this.charges.forEach(charge => {
			if (charge.isSelected === true) {
				selectedItems.push(charge.id);
			}
		});

		if (selectedItems.length === 0) {
			this.snack.snackError(ERROR_MESSAGES.pleaseSelectCharge);
			return;
		}

		this.fetchCharges = new Promise((resolve, reject) => {
			this.subscription.add(
				this.chargeService.postEditBulkActive(selectedItems, false, this.projectId).subscribe(newProject => {
					this.paginateRefresh();
					this.projectRouteService.setProject(newProject);
					resolve(newProject);
				}, reject)
			);
		});
	}

	/**
	 * navigate to Estimator tool passing current project Id
	 */
	public navigateToEstimatorTool(chargeId?): void {
		if (this.project.id) {
			this.navigationService.setRoute([`/${Constants.ROUTE_LINKS.measuringTool}/${this.project.id}`], {
				queryParams: {
					chargeId: chargeId,
					variationId: this.variationId,
				},
			});
		}
	}

	public onDeselectTab(): void {
		this.selectedTab = false;
	}

	/**
	 * Called when the expand all button is clicked. Expands or collapses all sub-groups.
	 */
	public onExpandAllClicked(setting: boolean = !this.isAllSubGroupsExpanded): void {
		this.isAllSubGroupsExpanded = setting;

		for (const subGroup of this.subGroupArray) {
			subGroup.isExpanded = this.isAllSubGroupsExpanded;
		}
	}

	/**
	 * Called when the select all checkbox is clicked. Selects or deselects all charges.
	 * @param setting
	 */
	public onSelectAllClicked(setting: boolean = !this.isAllChargesSelected): void {
		this.isAllChargesSelected = setting;

		if (this.subGroupArray && this.subGroupArray.length) {
			for (const subGroup of this.subGroupArray) {
				subGroup.isSelected = this.isAllChargesSelected;
				subGroup.items.forEach((item: ChargeModel) => (item.isSelected = this.isAllChargesSelected));
			}
		}

		this.onChargeSelected();
	}

	/**
	 * When a sub-group is selected, select all charges inside it.
	 * @param subGroup
	 */
	public onSelectSubGroup(subGroup: SubGroup): void {
		subGroup.items.forEach((item: ChargeModel) => (item.isSelected = subGroup.isSelected));
		this.onChargeSelected();
	}

	public onTabClick(): void {
		if (this.selectedTab === true) {
			this.selectedTab = false;
			this.deselectTabEmitter.emit();
		}
	}

	public openCommentsModal(charge): void {
		this.selectedCharge = new ChargeModel(charge);

		this.showCommentModal = true;
	}

	public openEditSubstrate(charge: ChargeModel): void {
		if (this.isEditOrder === true || !this.isEditAllowed || charge.chargeType === Constants.CHARGE_TYPES.AdditionalCharge) {
			return;
		}

		this.selectedCharge = new ChargeModel(charge);

		this.modalService.showModal({
			title: Constants.MODAL_TITLE.substrate,
			content: this.substrateEditRef,
			submitButton: {
				click: () => {
					let newCharge;
					const savingSubstrate = this.substrateEditDialogComponent.onSave();

					if (savingSubstrate) {
						this.subscription.add(
							savingSubstrate.subscribe(
								result => {
									newCharge = result;
									if (newCharge) {
										const index = this.charges.indexOf(charge);
										this.charges[index].substrate = newCharge.substrate;
										this.modalService.closeModal();
									}
								},
								err => {}
							)
						);
					}
				},
				text: Constants.MODAL_TEXT.submit,
				style: Constants.MODAL_STYLE.primary,
			},
			cancelButton: {
				click: () => {
					this.modalService.closeModal();
				},
				text: Constants.MODAL_TEXT.close,
				style: Constants.MODAL_STYLE.primary,
			},
		});
	}

	public openEditColour(charge: ChargeModel): void {
		if (this.isEditOrder === true || !this.isEditAllowed || charge.chargeType === Constants.CHARGE_TYPES.AdditionalCharge) {
			return;
		}
		this.selectedCharge = new ChargeModel(charge);
		this.modalService.showModal({
			title: Constants.MODAL_TITLE.colour,
			content: this.colourEditRef,
			submitButton: {
				click: () => {
					let newCharge;
					const savingSubstrate = this.colourEditDialogComponent.onSave();

					if (savingSubstrate) {
						this.subscription.add(
							savingSubstrate.subscribe(
								result => {
									newCharge = result;
									if (newCharge) {
										const index = this.charges.indexOf(charge);
										// Cannot use Object.assign here as old ._id remain in the new object that is using .id instead
										this.charges[index].colour = newCharge.colour;
										this.updateProjectDetailsTabs.emit();
										this.modalService.closeModal();
									}
								},
								err => {}
							)
						);
					}
				},
				text: Constants.MODAL_TEXT.submit,
				style: Constants.MODAL_STYLE.primary,
			},
			cancelButton: {
				click: () => {
					this.modalService.closeModal();
				},
				text: Constants.MODAL_TEXT.close,
				style: Constants.MODAL_STYLE.primary,
			},
		});
	}

	public openEditLocation(charge: ChargeModel): void {
		if (this.isEditOrder === true || !this.isEditAllowed) {
			return;
		}

		this.selectedCharge = new ChargeModel(charge);

		this.modalService.showModal({
			title: Constants.MODAL_TITLE.location,
			content: this.locationEditRef,
			submitButton: {
				click: async () => {
					this.locationEditDialogComponent
						.onSave()
						.pipe(
							tap(newCharge => {
								if (!newCharge) {
									return;
								}
								this.modalService.closeModal();

								this.projectFilterSubscription = this.projectService
									.getProjectFilterDropdowns(this.projectId, undefined, this.variationId ? this.variationId : undefined)
									.pipe(
										finalize(() => {
											this.projectFilterSubscription.unsubscribe();
										})
									)
									.subscribe((result: DropdownData) => {
										this.subLocations1 = result.subLocations1;
										this.subLocations2 = result.subLocations2;
										this.subLocations3 = result.subLocations3;
										this.substrates = result.substrates;
										// We want to reload the table if the location has been changed as the expandable rows are sorted by subloc1
										this.paginateRefresh();
									});
							})
						)
						.subscribe();
				},
				text: Constants.MODAL_TEXT.submit,
				style: Constants.MODAL_STYLE.primary,
			},
			cancelButton: {
				click: () => {
					this.modalService.closeModal();
				},
				text: Constants.MODAL_TEXT.close,
				style: Constants.MODAL_STYLE.primary,
			},
		});
	}

	/**
	 * Open and close section header upon click
	 * The totals row will not have a subGroup passed in and does not require any action
	 * @param subGroup
	 */
	public onSectionHeaderClick(subGroup: SubGroup): void {
		if (subGroup) {
			subGroup.isExpanded = !subGroup.isExpanded;
			this.setIsAllSubGroupsExpanded();
		}
	}

	public openEditCoatingSystemModal(charge: ChargeModel): void {
		if (charge.chargeType === Constants.CHARGE_TYPES.Boq) {
			this.editCoatingSystemModalConfig = new ItcModalConfig({
				...this.editCoatingSystemModalConfig,
				isConfirmDisabled: !this.isEditAllowed,
			});
			this.selectedCharge = new ChargeModel(charge);
			this.showEditCoatingSystemModal = true;
		}
	}

	/**
	 * Sets the project's isLegacyCalculation flag to false and calls the save function
	 */
	public overrideLegacyCalculation(): void {
		this.projectLoader = this.projectService.unsetLegacyCalculationFlag(this.projectId).pipe(
			tap(project => {
				this.projectRouteService.setProject(project);
				// This must be set here as the observable does not update the value in time for the
				// check in saveProjectCharges
				this.project.isLegacyCalculation = false;
				this.showLegacyCalculationOverrideModal = false;
				this.saveProjectCharges();
			})
		);
	}

	public paginateRefresh(pageSelected?: number): void {
		if (this.projectId) {
			const paginate: Paginate = this.getPaginateObject(pageSelected);

			this.updateCharges.emit(paginate);
		}
	}

	public refreshList(): void {
		this.paginateRefresh(1);
		this.updateTotalCount();

		if (this.filterSubstrate || this.filterEnvironment || this.filterSubLocation1 || this.filterSubLocation2 || this.filterSubLocation3 || this.filterCoatingSystem) {
			this.showSubGroups = false;
		} else {
			this.showSubGroups = this.project.showSubGroups ? this.project.showSubGroups : false;
		}
	}

	public resetSelectedCharge(): void {
		this.selectedCharge = undefined;
	}

	/**
	 * Saves the current charge order of the project, and the state of whether to show the project.
	 */
	public saveChargeOrder(): void {
		this.chargeOrderSubscription = this.getSaveChargeOrderRequest()
			.pipe(
				finalize(() => {
					this.chargeOrderSubscription.unsubscribe();
				})
			)
			.subscribe(() => {
				this.isEditOrder = false;
				this.paginateRefresh(this.currentPageNo);
			});
	}

	/**
	 * Saves Coating system, sets selected Charge to updated Charge
	 */
	public saveCoatingSystem(): void {
		this.isSavingCoatingSystem = true;
		const subscription = this.editCoatingSystemComponent
			.saveCoatingSystem()
			.pipe(
				finalize(() => {
					this.isSavingCoatingSystem = false;
					subscription.unsubscribe();
				})
			)
			.subscribe((data: BoqUpdateData) => {
				if (data) {
					this.selectedCharge = new ChargeModel(data.boq);
					this.projectRouteService.setProject(data.project);
					this.showEditCoatingSystemModal = false;
					this.resetSelectedCharge();
				}
			});
	}

	public saveComments(): void {
		if (this.isEditAllowed) {
			this.commentsEditDialogComponent.onSave();
		}

		this.showCommentModal = false;
	}

	/**
	 * Saves the projects additional charges array (to save order)
	 */
	public saveProjectCharges() {
		if (this.project.isLegacyCalculation) {
			this.showLegacyCalculationOverrideModal = true;
			return;
		}
		// Check if there are any calculations ongoing and set flag to true if so
		if (this.calculateBoqSubscription && !this.calculateBoqSubscription.closed) {
			this.isRequestingSave = true;
		} else {
			this.changeDetectorRef.detectChanges();
			return this.updateProjectCharges();
		}
	}

	public saveProjectChargesFromParent(): boolean {
		if (this.isEditOrder) {
			// Prevent charges from being saved if order is being edited
			return false;
		}
		this.saveProjectCharges();

		return true;
	}

	public setChargesList() {
		this.selectedTab = true;
		this.isEditOrder = false;
		this.refreshList();
	}

	/**
	 * Switches list view to display or not display inactive items.
	 */
	public toggleInactive(): void {
		this.filterIsActive = this.viewSelection === this.viewSelections[0];
	}

	/**
	 * Sets the edit order to active or false.
	 * Deselects all selected items.
	 */
	public toggleEditOrder(): void {
		this.isEditOrder = true;
		this.onSelectAllClicked(false);
	}

	public toggleShowFilters() {
		if (!!this.showFilterOptions) {
			this.clearFilterFields();
			this.showSubGroups = this.project.showSubGroups;
		}
		this.showFilterOptions = !this.showFilterOptions;
	}

	/**
	 * Toggles the state of whether to show sub-groups for the project, and saves the setting.
	 */
	public toggleSubgroups(): void {
		this.showSubGroups = !this.showSubGroups;
		this.isAllSubGroupsExpanded = false;
		this.setSubGroupsExpanded();
		this.setSubGroupArray();

		// Save the setting
		this.chargeOrderSubscription = this.projectService
			.postShowSubGroups(this.projectId, this.showSubGroups)
			.pipe(
				finalize(() => {
					this.chargeOrderSubscription.unsubscribe();
				})
			)
			.subscribe((project: Project) => {
				this.projectRouteService.setProject(project);
			});
	}

	public updateProjectCharges() {
		this.isRequestingSave = false;

		const errors = ValidatorHelper.checkErrorsInControlGroupArray(this.validators);
		if (errors) {
			this.snack.snackError(errors);
			return undefined;
		}

		if ((!this.charges && this.isLoaded === false) || this.charges.length === 0) {
			return {
				isActive: false,
				additionalChargesCost: 0,
				additionalChargesChargeOut: 0,
				chargeOrder: [],
				discount: 0,
				discountPercentage: 0,
				discountType: '',
				totalProfit: 0,
				probability: 0,
				isDiscountDisabled: false,
				isDiscountPercentage: false,
			};
		}

		this.fetchCharges = this.chargeService.postUpdateMany(this.charges, this.projectId).toPromise();
		return this.fetchCharges.then(projectItem => {
			this.projectRouteService.setProject(projectItem);
			this.paginateRefresh(this.currentPageNo);
			this.updateTotalCount();
			this.navigationWarningService.setStatus(false);
			return projectItem;
		});
	}

	/**
	 * If a checkbox is selected, we are not selecting all charges.
	 * @param selections
	 */
	public updateSelections(selections: any[]) {
		if (!selections.length) {
			this.isAllChargesSelected = false;
		}
	}

	/**
	 * BOQ Calculation
	 */
	private calculateLabourRates(chargeOutType: string, boq: BoqModel) {
		this.navigationWarningService.setStatus(true);

		if (boq.coatingSystem) {
			// Select rateCost based on the current value of isEbaRate
			if (boq.labourCost) {
				if (boq.isEbaRate) {
					// EBA rates are summed with the height and site allowance cost. Non-EBA rates ignore these values
					boq.labourCost.rateCost = new Decimal(boq.labourCost.rateEBA)
						.plus(boq.labourCost.heightAllowance || 0)
						.plus(boq.labourCost.siteAllowance || 0)
						.toNumber();
				} else {
					boq.labourCost.rateCost = boq.labourCost.rateNonEBA;
				}

				//lets do Boq calc only is everything selected
				if (this.projectId && boq && boq.heightRange && boq.product && boq.coatingSystem && boq.dimensions.unitType) {
					this.calculateBoqSubscription = this.calculationService
						.postBoqCalculate(this.projectId, boq, chargeOutType)
						.pipe(
							finalize(() => {
								this.finalizeCalculation();
							})
						)
						.subscribe((boqItem: any) => {
							const index = this.charges.findIndex(item => item.id === boqItem.id);

							if (index !== -1) {
								this.charges[index] = boqItem;
							}
							this.setSubGroupArray();
						});
				}
			}
		}
	}

	/**
	 * calculate totals for charges sub groups
	 */
	private calculateTotals(map: any): void {
		this.environmentsMapTotals = {};

		Object.keys(map).forEach(key => {
			this.environmentsMapTotals[key] = {};

			let costPerUnitTotal = 0;
			let ratePerUnitTotal = 0;

			this.environmentsMapTotals[key]['averageCostPerUnits'] = 0;
			this.environmentsMapTotals[key]['averageRatePerUnits'] = 0;
			this.environmentsMapTotals[key]['totalChargeOut'] = 0;
			this.environmentsMapTotals[key]['totalCosts'] = 0;
			this.environmentsMapTotals[key]['totalCount'] = 0;
			this.environmentsMapTotals[key]['totalBoqCount'] = 0;
			this.environmentsMapTotals[key]['totalDiscounts'] = 0;
			this.environmentsMapTotals[key]['totalGP'] = 0;
			this.environmentsMapTotals[key]['totalLabourHours'] = 0;
			this.environmentsMapTotals[key]['totalProfit'] = 0;
			this.environmentsMapTotals[key]['totalUnits'] = 0;
			this.environmentsMapTotals[key]['grossTotalChargeOut'] = 0;

			map[key].forEach(el => {
				let units;
				let unitType;
				if (el.useEstimatedValues) {
					units = el.estimatedDimensions.units || 0;
					unitType = el.estimatedDimensions.unitType;
				} else {
					units = el.dimensions.units || 0;
					unitType = el.dimensions.unitType;
				}

				if (unitType === 'M2') {
					costPerUnitTotal += this.ratePipe.transform(el.totalCostPerUnit || 0, 'sqm', 'sqm', 'sqft');
					ratePerUnitTotal += this.ratePipe.transform(el.chargeOutAmounts?.ratePerUnit || 0, 'sqm', 'sqm', 'sqft');
				} else if (unitType === 'Lineal Metre') {
					costPerUnitTotal += this.ratePipe.transform(el.totalCostPerUnit || 0, 'lm', 'lm', 'lft');
					ratePerUnitTotal += this.ratePipe.transform(el.chargeOutAmounts?.ratePerUnit || 0, 'lm', 'lm', 'lft');
				} else {
					costPerUnitTotal += el.totalCostPerUnit || 0;
					ratePerUnitTotal += el.chargeOutAmounts?.ratePerUnit || 0;
				}

				this.environmentsMapTotals[key]['totalChargeOut'] += el.totalCharge || 0;
				this.environmentsMapTotals[key]['totalCosts'] += el.totalCost || 0;
				this.environmentsMapTotals[key]['totalCount'] += 1;
				this.environmentsMapTotals[key]['totalBoqCount'] += el.chargeType === this.chargeTypes.Boq ? 1 : 0;
				this.environmentsMapTotals[key]['totalDiscounts'] += el.discount || 0;
				this.environmentsMapTotals[key]['totalLabourHours'] += el.labourHoursTotal || 0;
				this.environmentsMapTotals[key]['totalProfit'] += el.totalProfit || 0;
				this.environmentsMapTotals[key]['totalUnits'] += units;
				this.environmentsMapTotals[key]['grossTotalChargeOut'] += el.grossTotalChargeOut || 0;
			});

			this.environmentsMapTotals[key]['averageRatePerUnits'] = ratePerUnitTotal === 0 ? 0 : ratePerUnitTotal / this.environmentsMapTotals[key]['totalBoqCount'];
			this.environmentsMapTotals[key]['averageCostPerUnits'] = costPerUnitTotal === 0 ? 0 : costPerUnitTotal / this.environmentsMapTotals[key]['totalBoqCount'];
			this.environmentsMapTotals[key]['totalGP'] =
				this.environmentsMapTotals[key]['totalChargeOut'] === 0 ? 0 : this.environmentsMapTotals[key]['totalProfit'] / this.environmentsMapTotals[key]['totalChargeOut'];
		});
	}

	private initialisePage(projectId: string): void {
		this.projectFilterSubscription = this.projectService
			.getProjectFilterDropdowns(projectId, undefined, this.variationId ? this.variationId : undefined)
			.pipe(
				finalize(() => {
					this.projectFilterSubscription.unsubscribe();
				})
			)
			.subscribe((result: DropdownData) => {
				if (result) {
					this.subLocations1 = result.subLocations1;
					this.subLocations2 = result.subLocations2;
					this.subLocations3 = result.subLocations3;
					this.substrates = result.substrates;
					this.coatingSystems = result.coatingSystems;
				}
				this.setChargesList();
			});
	}

	private getPaginateObject(pageSelected?: number, limit?: number, applyFilter: boolean = true): Paginate {
		if (pageSelected) {
			this.currentPageNo = pageSelected;
		}

		const paginate: Paginate = {
			skip: (this.currentPageNo - 1) * this.paginationLimit,
			limit: isNaN(limit) ? this.paginationLimit : limit,
			params: {
				project: this.projectId,
				isActive: true,
			},
		};

		if (applyFilter) {
			const chargesFilter = {
				projectId: this.projectId,
				substrate: this.filterSubstrate,
				environment: this.filterEnvironment,
				subLocation1: this.filterSubLocation1,
				subLocation2: this.filterSubLocation2,
				subLocation3: this.filterSubLocation3,
				coatingSystem: this.filterCoatingSystem,
				viewSelection: this.viewSelection,
				isActive: this.filterIsActive,
			};
			this.localStorage.setChargesFilter(chargesFilter);

			paginate.params.substrate = this.filterSubstrate;
			paginate.params.environment = this.filterEnvironment;
			paginate.params.subLocation1 = this.filterSubLocation1;
			paginate.params.subLocation2 = this.filterSubLocation2;
			paginate.params.subLocation3 = this.filterSubLocation3;
			paginate.params.coatingSystem = this.filterCoatingSystem;
			paginate.params.isActive = this.filterIsActive;
		}

		return paginate;
	}

	/**
	 * Saves the current order of charges.
	 * @private
	 */
	private getSaveChargeOrderRequest() {
		const postList = [];
		let i = 0;

		this.subGroupArray.forEach((subGroup: SubGroup) => {
			postList.push(
				...subGroup.items.map((item: ChargeModel) => {
					return { id: item.id, order: i++ };
				})
			);
		});
		// Look for variation id in the first charge (if the first one has it, we can assume they all do)
		return this.chargeService.postChargeOrder(postList, this.projectId, this.subGroupArray[0].items[0].variation);
	}

	private getSelectedItems(): string[] {
		const selectedItems: string[] = [];
		this.isSelectBoq = false;
		this.isSelectAdditionalCharge = false;

		this.charges.forEach(charge => {
			if (charge.isSelected === true) {
				selectedItems.push(charge.id);
				if (charge.chargeType === this.chargeTypes.Boq) {
					this.isSelectBoq = true;
				} else {
					this.isSelectAdditionalCharge = true;
				}
			}
		});

		return selectedItems;
	}

	private scrollToBoq(): void {
		if (this.scrollToBoqId) {
			for (const subGroup of this.subGroupArray) {
				for (const item of subGroup.items) {
					if (item.id === this.scrollToBoqId) {
						subGroup.isExpanded = true;
						this.setIsAllSubGroupsExpanded();
						setTimeout(() => {
							const elementToScrollTo: Element = document.getElementById(this.scrollToBoqId);
							this.scrollService.scrollToElement(elementToScrollTo);
							this.scrollToBoqId = undefined;
						}, 0);
						return;
					}
				}
			}
		}
	}

	private setChargesFilter(): void {
		let chargesFilter = this.localStorage.getChargesFilter();

		if (chargesFilter && chargesFilter.projectId === this.projectId) {
			this.showFilterOptions = !!(
				chargesFilter.substrate ||
				chargesFilter.environment ||
				chargesFilter.subLocation1 ||
				chargesFilter.subLocation2 ||
				chargesFilter.subLocation3 ||
				chargesFilter.coatingSystem
			);
			this.filterSubstrate = chargesFilter.substrate;
			this.filterEnvironment = chargesFilter.environment;
			this.filterSubLocation1 = chargesFilter.subLocation1;
			this.filterSubLocation2 = chargesFilter.subLocation2;
			this.filterSubLocation3 = chargesFilter.subLocation3;
			this.filterCoatingSystem = chargesFilter.coatingSystem;
			this.showSubGroups = this.project.showSubGroups && !this.showFilterOptions;
			this.viewSelection = chargesFilter.viewSelection;
		} else {
			this.localStorage.removeChargesFilter();
			this.viewSelection = this.viewSelections[0];
			this.clearFilterFields();
			chargesFilter = null;
			this.isAllSubGroupsExpanded = false;
		}
		this.toggleInactive();
	}

	/**
	 * Sets a flag for whether all sub-groups are expanded.
	 * @private
	 */
	private setIsAllSubGroupsExpanded(): void {
		// If we find one collapsed item, set to false. Otherwise, true.
		this.isAllSubGroupsExpanded = !this.subGroupArray.find((item: SubGroup) => !item.isExpanded);
	}

	private setModalConfig(): void {
		this.modalConfig = new ItcModalConfig({
			confirmButtonText: this.textConstants.saveComments,
			showCancelButton: false,
			showCloseModalButton: true,
			title: this.textConstants.writeAComment,
		});
		this.legacyCalculationOverrideModal = new ItcModalConfig({
			confirmButtonText: this.textConstants.confirm,
			showCancelButton: false,
			showCloseModalButton: true,
			title: this.textConstants.confirmCalculationOverride,
		});
	}

	/**
	 * sets Edit Permission for project, allowed if project status is Active or Target, and user has permission to edit project
	 * @private
	 */
	private setPermissions(): void {
		const isEditPermittedByRole = this.rolesPermissions.canEditProject(this.isUserKeyRole);
		const isEditPermittedByStatus = this.rolesPermissions.canEditProjectByStatus(this.project);

		this.isEditAllowed = isEditPermittedByRole && isEditPermittedByStatus && !this.isUserLocked;
	}

	/**
	 * Group charges together by subLocation1 for BOQs and additional charges
	 */
	private setSubGroupArray(): void {
		// Set the sub-groups that were expanded previously
		if (this.subGroupArray) {
			this.setSubGroupsExpanded();
		} else if (this.chargesService.subGroupsExpanded) {
			this.subGroupsExpanded = this.chargesService.subGroupsExpanded;
			this.chargesService.subGroupsExpanded = [];
		}

		this.environmentsMap = {};
		this.subGroupArray = [];
		// This is needed as JS object property insertion order isn't reliable.
		const subGroupOrder = [];

		// Separate each charge into sub groups based on subLocation1 regardless of showSubGroups state
		this.charges.forEach((charge: ChargeModel) => {
			if (!this.environmentsMap[charge.subLocation1]) {
				this.environmentsMap[charge.subLocation1] = [];
				subGroupOrder.push(charge.subLocation1);
			}
			this.environmentsMap[charge.subLocation1].push(charge);
		});

		// Create a matrix we can re-order
		for (const key of subGroupOrder) {
			if (this.environmentsMap[key]) {
				this.subGroupArray.push({
					key: key,
					isExpanded: this.subGroupsExpanded ? this.subGroupsExpanded.includes(key) : false,
					isSelected: false,
					items: this.environmentsMap[key],
				});
			}
		}

		this.calculateTotals(this.environmentsMap);
	}

	/**
	 * Finds which sub-groups are expanded and records them.
	 * If sub-groups arent shown, no groups are expanded.
	 * @private
	 */
	private setSubGroupsExpanded(): void {
		if (this.showSubGroups) {
			this.subGroupsExpanded = this.subGroupArray.reduce((reduce: string[], subGroup: SubGroup) => {
				if (subGroup.isExpanded) {
					reduce.push(subGroup.key);
				}
				return reduce;
			}, []);
		} else {
			this.subGroupsExpanded = [];
		}
		this.chargesService.subGroupsExpanded = this.subGroupsExpanded;
	}

	private updateTotalCount(): void {
		if (this.chargeTotals) {
			this.totalCount = Math.ceil(this.chargeTotals.totalCount / this.paginationLimit);
		}
	}

	private updateValidators(charges: ChargeModel[]): void {
		this.validators = [];
		charges.forEach((charge: ChargeModel) => {
			if (charge.chargeType === this.chargeTypes.Boq) {
				this.validators[charge.id] = GetBoqChargesValidation(this.isChargeDiscountDisabled, charge);
			} else {
				this.validators[charge.id] = GetAdditionalChargeChargesValidation(
					this.isChargeDiscountDisabled,
					charge.totalCharge,
					charge.totalCost,
					charge.labourHoursTotal,
					charge.discount
				);
			}
		});
	}

	private setIsAssociatedToMeasuringToolPage(): void {
		if (this.charges.length && this.measuringToolBoqIds.length) {
			for (const charge of this.charges) {
				charge.isAssociatedToMeasuringToolPage = this.measuringToolBoqIds.includes(charge.id);
			}
		}
	}

	private setMeasuringToolBoqIds(projectId: string): void {
		this.measuringToolBoqIdsObservable = this.measuringToolService.getMeasuringToolBoqIds(projectId).pipe(
			tap((listBoqIds: string[]) => {
				if (listBoqIds) {
					this.measuringToolBoqIds = listBoqIds;
					this.setIsAssociatedToMeasuringToolPage();
					this.setSubGroupArray();
				}
			})
		);
	}

	private initialiseDropdownOptions(): void {
		this.dropdownOptions = [
			{
				value: 'Additional Charge',
				disabled: !this.isTraineeAndAbove
			},
			{
				value: 'Bill of Quantity',
				disabled: !this.isTraineeAndAbove
			},
		];
	}

	public openActionMenuPopover(event: MouseEvent) {
		this.actionMenuPopover.open(event);
	}

	public optionSelected(option: DropdownOption): void {
		if (option.disabled) return;

		this.setSubGroupsExpanded();
		this.chargesService.currentChargeId = undefined;

		if (option.value === 'Additional Charge') {
			this.navigateToCharge('additionalcharge');
		} else if (option.value === 'Bill of Quantity') {
			this.navigateToCharge('boq');
		}
	}

	public navigateToCharge(chargeType: string) {
		//go to the selected charge type.
		this.navigationService.setRoute([`/${Constants.ROUTE_LINKS.project}/${this.projectId}/${chargeType}`]);

	}
}
