import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { faChevronDown, faChevronUp, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { projectStatuses } from '../../../components/project/common/project.constants';
import { CommentPreviewComponent } from '../../../components/project/components/comment-preview/comment-preview.component';
import { Constants, getStatuses, PROJECT_STATUS, TEXT } from '../../../helpers';
import { GetProjectStatusValidation, ItemLinkConfig, Project } from '../../../interfaces';
import { CompanyListText } from '../../../pages/company/company-list/common/company-list.constants';
import { projectRoutes } from '../../../pages/project/common/project.constants';
import { ProjectRouteService } from '../../../pages/project/common/project.service';
import { AuthService } from '../../../services/auth.service';
import { ColourService } from '../../../services/colour.service';
import { NavigationService } from '../../../services/navigation.service';
import { ProjectSessionService } from '../../../services/project-session.service';
import { ProjectService } from '../../../services/project.service';
import { CoatingSystemImageHeight, STRING_CONSTANTS } from './common/constants';
import { SelectedProjectService } from '../../../services/select-project.service';

@Component({
	selector: 'app-generic-table',
	templateUrl: './generic-table.component.html',
	styleUrls: ['./generic-table.component.scss'],
})
export class GenericTableComponent implements OnInit {
	@Input()
	public set listItems(listItems: any[]) {
		if (listItems) {
			this._listItems = listItems;

			// Set expanded boolean for variations chevron dropdown
			for (const item of listItems) {
				if (item.variations?.length) {
					item.isExpanded = false;
				}
			}
		}
	}
	public get listItems(): any[] {
		return this._listItems;
	}

	constructor(
		public colourService: ColourService,
		private projectRouteService: ProjectRouteService,
		private nav: NavigationService,
		private projectService: ProjectService,
		private selectedProjectService: SelectedProjectService
	) {}

	@Input()
	public headers: { [key: string]: string }[] = [];
	@Input()
	public readonly imagePromises: Promise<void>[];
	@Input()
	public isBulkEdit: boolean = false;
	@Input()
	public isEditOrder: boolean = false;
	@Input()
	public showLines: boolean = true;
	@Input()
	public itemLinkConfig: ItemLinkConfig;
	@Input() type?: string;

	@Output()
	public bulkItemsSelected: EventEmitter<string[]> = new EventEmitter();
	@Output()
	public itemSelected: EventEmitter<any> = new EventEmitter();

	@ViewChild('timeline') public timeline: CommentPreviewComponent;

	public chevronDown: IconDefinition = faChevronDown;
	public chevronUp: IconDefinition = faChevronUp;
	public coatingSystemImageHeight = CoatingSystemImageHeight;
	public projectStatus = Constants.PROJECT_STATUS;
	public projectStatuses: typeof projectStatuses = projectStatuses;
	public recentProjectsLink = '/' + Constants.ROUTE_LINKS.project + '/';
	public recentProjectsVariationsLink = '/' + projectRoutes.variations + '/';
	public sortableOptions = { animation: 300, forceFallback: true };
	public statusStyleText = Constants.PROJECT_STATUS_STYLE_FILL;
	public stringConstants = STRING_CONSTANTS;
	public svgIconPaths = Constants.SVG_ICON_PATHS;
	public statusValidators = GetProjectStatusValidation();
	public currentDropdownItem: any;
	public projectStatusEditObservable: Observable<any>;
	public libraryTypesConst = Constants.LIBRARY_TYPES;

	private _listItems: any[] = [];

	protected readonly PROJECT_STATUS = PROJECT_STATUS;
	protected readonly Object = Object;
	protected readonly CompanyListText = CompanyListText;
	protected readonly TEXT = TEXT;

	public ngOnInit(): void {}

	public expandRow(event: MouseEvent, rowItem: any): void {
		if (rowItem.variations?.length) {
			event.stopPropagation();
			event.preventDefault();
			rowItem.isExpanded = !rowItem.isExpanded;
		}
	}

	public consumeClick(event: MouseEvent): void {
		event.stopPropagation();
		event.preventDefault();
	}

	public statusPillSelect(event, item): void {
		this.projectStatusEditObservable = this.projectService.editProjectStatus(item.id, event, new Date()).pipe(tap(project => (item = project)));
	}

	public selectItem(item, event?: MouseEvent): void {
		if (this.type === this.libraryTypesConst.project.key && item) {
			this.selectedProjectService.setSelectedProject(item);
		}
		this.itemSelected.emit(item);
	}

	public isStandardField(header: { [p: string]: string }): boolean {
		return (
			!header.isImage &&
			!header.isColour &&
			!header.isColourRect &&
			!header.isDate &&
			!header.isProjectStatus &&
			!header.isRGB &&
			!header.isCurrency &&
			!header.isPercent &&
			!header.isStatus &&
			!header.isVariation &&
			!header.isArray &&
			!header.isComment &&
			!header.isClient &&
			!header.isStatusPill &&
			!header.isDocument
		);
	}

	public selectedBulkItem(item: any): void {
		item.isSelected = !item.isSelected;
		const emitIds = [];

		for (const listItem of this.listItems) {
			if (listItem.isSelected === true) {
				emitIds.push(listItem.id);
			}
		}

		this.bulkItemsSelected.emit(emitIds);
	}

	public variationSelected(projectId: string, variationId: string): void {
		this.projectRouteService.setVariationId(variationId);
	}

	protected readonly getStatuses = getStatuses;
}
