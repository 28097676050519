import { Injectable } from '@angular/core';
import { DialogButtonConfig, DialogComponent } from '../../@mos-core/components/dialog/dialog.component';

@Injectable({
	providedIn: 'root'
})
export class ConfirmationDialogService {
	private dialog: DialogComponent;

	public setDialog(dialog: DialogComponent) {
		this.dialog = dialog;
	}

	public confirm(message: string, confirmLabel: string, confirmAction: () => void, confirmType?: 'danger'| 'primary', cancelAction?: () => void, cancelLabel?: string): void {
		const buttons: DialogButtonConfig[] = [
			{
				label: confirmLabel,
				type: confirmType ? confirmType : 'danger',
				action: () => {
					confirmAction();
					this.dialog.close();
				}
			},
			{
				label: cancelLabel ? cancelLabel : 'Cancel',
				type: 'normal',
				action: () => {
					cancelAction ? cancelAction(): null;
					this.dialog.close()
				},
			}
		];

		this.dialog.message = message;
		this.dialog.buttons = buttons;
		this.dialog.open();
	}
}