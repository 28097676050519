import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class MeasureSystemService {
	public userPreferred: string;
	public preferredSystem: BehaviorSubject<string> = new BehaviorSubject('metric');

	constructor(private localStorageService: LocalStorageService) {
		if (environment.us) {
			this.preferredSystem.next('imperial');
		}
	}
}